<script setup>
import { ref, defineEmits } from 'vue';
import { useRoute } from 'vue-router';
import AccountOverview from './AccountOverview.vue'

const route = useRoute();
const emit = defineEmits(['closeOffcanvas']);

const handleLinkClick = () => {
  emit('closeOffcanvas');
};

/*
{
    icon: 'fa-wand-magic-sparkles', label: 'Campaign', submenu: [
      { label: 'Create Campaign', route: '/d/c/create' },
      { label: 'My Campaigns', route: '/d/c/my' },
      { label: 'Current Campaigns', route: '/d/c/current' }
    ],
    isCollapsed: true, // Track collapse state
  },
  */

const menuItems = ref([
  { icon: 'fa-calendar-days', label: 'Schedule', route: '/d/schedule' },
  { icon: 'fa-chart-simple', label: 'Analytics', route: '/d/analytics' },

  { icon: 'fa-hand-holding-dollar', label: 'Rewards', route: '/d/rewards' },
  { icon: 'fa-gear', label: 'Settings', route: '/d/settings' },
]);

const hasSubmenu = (item) => item.submenu && item.submenu.length > 0;

const toggleCollapse = (index) => {
  if (hasSubmenu(menuItems.value[index])) {
    menuItems.value[index].isCollapsed = !menuItems.value[index].isCollapsed;
  }
};

const isActiveRoute = (routePath) => {
  return route.path === routePath;
};

const isSubmenuActive = (submenu) => {
  return submenu.some(subItem => isActiveRoute(subItem.route));
};

// Set initial collapse state based on active route
menuItems.value.forEach((item, index) => {
  if (hasSubmenu(item) && isSubmenuActive(item.submenu)) {
    item.isCollapsed = false;
  }
});
</script>

<template>
  <AccountOverview />
  <ul class="list-group">
    <li v-for="(item, index) in menuItems" 
        :key="index" 
        :class="{ aroute: isActiveRoute(item.route) }"
        class="list-group-item">
      <router-link 
        v-if="!hasSubmenu(item)" 
        :to="item.route" 
        @click="handleLinkClick"
        class="d-flex align-items-center justify-content-between w-100">
        <div>
          <i :class="`fa-solid ${item.icon}`"></i> {{ item.label }}
        </div>
      </router-link>
      <div v-else @click="toggleCollapse(index)" class="w-100">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <i :class="`fa-solid ${item.icon}`"></i> {{ item.label }}
          </div>
          <div class="submenu-icon">
            <i :class="`fa-solid ${item.isCollapsed ? 'fa-angle-right' : 'fa-angle-down'}`"></i>
          </div>
        </div>
        <ul v-if="hasSubmenu(item)" class="list-group list-group-flush"
            v-show="!item.isCollapsed">
          <li v-for="(subItem, subIndex) in item.submenu" 
              :key="subIndex" 
              :class="{ aroute: isActiveRoute(subItem.route) }"
              class="list-group-item">
            <router-link :to="subItem.route" class="d-block w-100">{{ subItem.label }}</router-link>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</template>

<style scoped>
.submenu-icon {
  cursor: pointer;
}

.list-group {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.list-group-item {
    border: none;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
     font-size: 20px;
}
.list-group-item .submenu-icon {
  display: flex;
  align-items: center;
}

.list-group-item > .list-group-flush {
  margin-top: 10px;
}


.router-link {
  text-decoration: none;
  color: inherit;
  display: block;
  width: 100%;
}

.d-flex {
  display: flex;
}

.w-100 {
  width: 100%;
}

.list-group li i {
    margin-right: 20px;
}

.aroute {
    background-color: #2980b9;
    color: #fff;
}
.aroute a {
    color: #fff;
}

li a {
    color: #000;
    text-decoration: none;
}

.offcanvas-lg {
    padding-left: 0px;
}
</style>
