<template>
    <div class="account-overview">
        <div class="flex-item navbar-brand">
            <span class="ms-2">/farthercast </span>
        </div>
        <img :src="userPFP" />
        <p>👋 {{ userDisplayName }}</p>
        <p> <i class="fa-solid fa-coins pr-3"></i> <span class="spinner-border spinner-border-sm" role="status" v-if="!userCredits"></span>
            <span v-if="userCredits">{{ formatCredits(userCredits) }}</span>
            <i class="fa-solid fa-right-from-bracket ms-3 d-inline-block" @click="handleClick" data-bs-toggle="tooltip" title="" data-bs-original-title="Logout"></i></p>
    </div>
</template>


<script>
import { ref, onMounted, onUnmounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
    setup() {

        const error = ref(null)
        const store = useStore()
        const router = useRouter()

        const handleClick = async () => {
        //   console.log("logout")
            try {
                await store.dispatch('logout')
                // console.log("logout")
                router.push({ name: 'Home' })
            } catch (err) {
                // console.log(err)
                error.value = err.message
            }
        }

        const userCredits = computed(() => {
            if (store.state.userCredits !== null) {
                return store.state.userCredits.toLocaleString(); 
            } 
            return null;
        });

        const userDisplayName = computed(() => {
            if (store.state.userDisplayName !== null) {
                return store.state.userDisplayName
            } 
            return null;
        });

        const userPFP = computed(() => {
            if (store.state.userPFP !== null) {
                return store.state.userPFP
            } 
            return "https://firebasestorage.googleapis.com/v0/b/farthercast-57cf1.appspot.com/o/VOmhrdmvTdWabJcU1mh22Jqnczo2%2Fimages%2F39164850-3542-11ef-a630-0f230b6ae5cb?alt=media&token=a21b50fe-c6af-40ea-a7d7-1a294d581d52";
        });

        const formatCredits = (credits) => {
            if (credits !== null) {
                return credits.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
            }
            return null;
        };




        return { handleClick, userCredits, formatCredits, userDisplayName, userPFP }
    }


}
</script>



<style scoped>
.navbar-brand {
    font-family: "Lusitana", serif;
    font-weight: 800;
    font-size: 30px;
    color: #410780;
    max-width: 250px;
}

.account-overview {
    margin: 0 auto;
    max-width: 90%;
    padding: 10px;
    text-align: center;
}

.account-overview img {
    max-width: 100px;
    height: 100px;
    border-radius: 50px;
    margin: 30px auto;
}
</style>
