<script setup>
import { ref, onMounted, onUnmounted, watch, computed } from "vue";
import { useStore } from 'vuex'
import {
    getFirestore,
    collection,
    getDocs,
    doc,
    setDoc,
    query,
    where,
    onSnapshot,

} from "firebase/firestore";
import { auth } from "../firebase/config";

const linkedAccounts = ref([]); // Initialize as an empty array
const isLoading = ref(true); // Initialize isLoading as true
const checkedAccounts = ref({});
const events = ref([]);
const store = useStore();
const loadingAccountSpinner = computed(() => store.state.loadingAccountSpinner);



// Fetch linked accounts
async function fetchLinkedAccounts() {
    const db = getFirestore();
    const currentUser = auth.currentUser;

    if (currentUser) {
        const userUUID = currentUser.uid;

        try {
            const linkedAccountsRef = collection(db, "users", userUUID, "linkedAccounts");
            const querySnapshot = await getDocs(linkedAccountsRef);

            // Update linkedAccounts only if there are results
            linkedAccounts.value = querySnapshot.docs.map((doc) => {
                const data = doc.data();
                return {
                    fid: data.fid,
                    username: data.username,
                };
            });
        } catch (error) {
            console.error("Error fetching linked accounts:", error);
        } finally {
            isLoading.value = false; // Update isLoading after request completes
        }
    } else {
        // console.log("No user signed in.");
    }
}


// Listen for changes to linked accounts
async function listenForLinkedAccountsChanges() {
  const db = getFirestore();
  const currentUser = auth.currentUser;

  if (currentUser) {
    const userUUID = currentUser.uid;
    const linkedAccountsRef = collection(db, "users", userUUID, "linkedAccounts");

    // Listen for changes to the linked accounts collection
    const unsubscribe = onSnapshot(linkedAccountsRef, (snapshot) => {
      linkedAccounts.value = snapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          fid: data.fid,
          username: data.username,
          main: data.main || false,
        };
      });
      // console.log("Linked accounts:", linkedAccounts.value); // Log linkedAccounts array

      // Automatically check accounts with main set to true
      linkedAccounts.value.forEach(account => {
        if (account.main) {
          toggleCheckbox(account.fid)
        }
      });
    });

    return unsubscribe;
  } else {
    // console.log("No user signed in.");
  }
}



async function handleSubmitNewEvents(accountId) {
    await store.dispatch('addEventData', accountId)
}

// Define the onSignInSuccess function
window.onSignInSuccess = async function(data) {
    // console.log("Sign in successful sidebar:", data);

    // // Check if the fid already exists in linkedAccounts
    if (!linkedAccounts.value.includes(data.fid)) {
        // Add the fid from the data object to the linkedAccounts array
        linkedAccounts.value.push(data.fid);

        try {
            const db = getFirestore();
            const currentUser = auth.currentUser;

            if (currentUser) {
                const userUUID = currentUser.uid;

                // Document reference
                const docRef = doc(db, "users", userUUID, "linkedAccounts", data.fid);

                // Set document data
                await setDoc(docRef, {
                    fid: data.fid,
                    uuid: data.signer_uuid,
                    username: "username",
                    calendarId: "account_" + (linkedAccounts.value.length),
                    linkedAt: new Date().toISOString(),
                });
            } else {
                // console.log("No user signed in.");
            }
        } catch (error) {
            console.error("Error writing document: ", error);
        }
    } else {
        // console.log("Account already linked:", data.fid);
    }
};

function toggleCheckbox(account) {
    store.commit('setLoadingAccountSpinner', true);
    checkedAccounts.value[account] = !checkedAccounts.value[account];
    const anyChecked = Object.values(checkedAccounts.value).some((isChecked) => isChecked);

    if (!anyChecked) {
        store.commit('setViewableAccounts', []);


    } else {
        store.commit('setViewableAccounts', checkedAccounts.value);

    }
}

function handleCardClick(event) {
  const card = event.currentTarget;
  const siwnButton = card.querySelector('#siwn-button');
  if (siwnButton) {
    siwnButton.click();
  }
}


onMounted(async () => {
    // Fetch linked accounts when component is mounted
    fetchLinkedAccounts();
     const unsubscribeLinkedAccounts = listenForLinkedAccountsChanges();

     const script = document.createElement('script');
      script.src = "https://neynarxyz.github.io/siwn/raw/1.2.0/index.js";
      script.async = true;
      document.body.appendChild(script);

      // Add event listener for the SIWN button
      // document.addEventListener('click', handleSIWNButtonClick);


    

});
</script>


<template>
  <div class="linked-accounts-container">
    <ul class="flex-container">
      <li class="flex-item fc-card wide-33 p-2" v-if="isLoading">
        <span class="spinner-border" role="status"></span>
        <p>Loading Linked Accounts</p>
      </li>
      <li class="flex-item fc-card wide-33 linked-account p-2" v-else-if="linkedAccounts.length === 0">
        No linked accounts found.
      </li>
      <li
        v-for="account in linkedAccounts"
        :key="account.fid"
        class="p-2 flex-item fc-card wide-33 linked-account"
        @click="toggleCheckbox(account.fid)"
      >
        <label :class="{ visible: checkedAccounts[account.fid], 'not-visible': !checkedAccounts[account.fid] }" class="account-label">
          <div class="linked-account-spinner spinner-border spinner-border-sm text-secondary" v-if="loadingAccountSpinner" role="status"></div>
          <!-- Replace this div with an actual image or content -->
          <div v-if="checkedAccounts[account.fid]" class="visible">
            <img src="https://farthercast-57cf1.web.app/visible.png" />
          </div>
          <div v-else class="not-visible">
            <img src="https://farthercast-57cf1.web.app/not-visible.png" />
          </div>
          <input
            class="form-check-input"
            type="checkbox"
            :id="'checkbox-' + account.fid"
            v-model="checkedAccounts[account.fid]"
          />
        </label>
        <span class="account-name">{{ account.username }}</span>
      </li>
      <li @click="handleCardClick" class="flex-item wide-33 neynar_signin" data-client_id="589c91c0-3ff2-4b3a-bad8-3e3c9e0de579" data-success-callback="onSignInSuccess" data-text="+ Link Account"></li>
    </ul>
  </div>
</template>



<style scoped>
.flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.flex-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.account-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.account-name {
  margin-top: 10px;
}

.form-check-input {
  display: none; /* Hide the checkbox */
}

.spinner-border {
  margin-bottom: 5px;
}

.visible img, .not-visible img {
    width: 50px;
    height: 50px;
}

.flex-item.linked-account {
    position: relative;
    max-width: 200px;
    max-width: 200px;
}

.flex-item.linked-account:hover {
    cursor: pointer;
}

.linked-account-spinner {
    position: absolute;
    right: 10px;
    top: 10px;
}

.linked-accounts-container ul {
    padding-left: 0;
}

.flex-item.neynar_signin {
    border: 2px dashed #ADD8E6;
    background-color: #fff;
    color: #ADD8E6;
    border-radius: 10px;
    max-width: 250px;
    cursor: pointer; /* Add this line */
}

.flex-item.neynar_signin:hover {
    border-color: #333;
}

.flex-item.neynar_signin #siwn-button {
    display: none;
    visibility: hidden;
    pointer-events: none; /* Add this line */
}
</style>