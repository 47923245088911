<template>
    <div class="alert alert-primary mb-3" role="alert">
        <div class="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
            <div class="d-flex align-items-center mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-4" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
                <span>The points system is still being built as the site is developed. Expect more ways to earn. <strong>Next update in {{ timeUntilMidnight }}</strong></span  >
              </div>
            </div>
          </div>
        
            <div class="alert alert-danger mb-3" role="alert">
            <div class="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
              <div class="d-flex align-items-center mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-4" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
                <span>These numbers are as accurate as the API responses for DEGEN. They may change.</span>
            </div>
        </div>
    </div>
    
    <div class="grid-container mb-3">
        <div class="grid-item">
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="mb-0">Pool</h4>
                <i class="fa-solid fa-piggy-bank icon"></i>
            </div>
            <div class="mt-3">
                <p class="display-3 mb-0">{{ currentPool }}</p>
            </div>
        </div>
    
        <div class="grid-item">
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="mb-0">Burn</h4>
                <i class="fa-solid fa-fire icon"></i>
            </div>
            <div class="mt-3">
                <p class="display-3 mb-0">{{ toBurn }}</p>
            </div>
        </div>
    
    
        <div class="grid-item">
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="mb-0">Lock</h4>
                <i class="fa-solid fa-lock icon"></i>
            </div>
            <div class="mt-3">
                <p class="display-3 mb-0">{{ toLock }}</p>
            </div>
        </div>
    </div>
    
    <div class="container">
        <h2 class="mb-4">Season 2 Leaderboard</h2>
    
        <table class="table table-striped table-hover fc-card p-5 text-center">
            <thead>
                <tr>
                    <th scope="col">Rank</th>
                    <th scope="col">Username</th>
                    <th scope="col">Display Name</th>
                    <th scope="col">Points</th>
                    <th scope="col">Possible Reward</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(user, index) in paginatedLeaderboard" :key="user.username">
                    <th scope="row">{{ (currentPage - 1) * itemsPerPage + index + 1 }}</th>
                    <td><a :href="'https://warpcast.com/' + user.username" target="_blank">{{ user.username }}</a></td>
                    <td>{{ user.display_name }}</td>
                    <td>{{ user.points }}</td>
                    <td>{{ user.possible_reward }} $DEGEN</td>
                </tr>
            </tbody>
        </table>
    
    
        <nav aria-label="Leaderboard pagination">
            <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                    <a class="page-link" href="#" @click.prevent="prevPage">Previous</a>
                </li>
                <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                    <a class="page-link" href="#" @click.prevent="goToPage(page)">{{ page }}</a>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                    <a class="page-link" href="#" @click.prevent="nextPage">Next</a>
                </li>
            </ul>
        </nav>
    
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed, watch } from 'vue';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';

export default {
    name: 'LeaderboardView',
    setup() {
        const leaderboard = ref([]);
        const timeUntilMidnight = ref('');
        const currentPool = ref(0);
        const toBurn = ref(0);
        const toLock = ref(0)
        const currentPage = ref(1);
        const itemsPerPage = 10;
        const totalPages = ref(0);
        let unsubscribe;
        let timer;

        const paginatedLeaderboard = computed(() => {
            const start = (currentPage.value - 1) * itemsPerPage;
            const end = start + itemsPerPage;
            return leaderboard.value.slice(start, end);
        });

        const calculateTotalPages = () => {
            totalPages.value = Math.ceil(leaderboard.value.length / itemsPerPage);
        };

        const nextPage = () => {
            if (currentPage.value < totalPages.value) {
                currentPage.value++;
            }
        };

        const prevPage = () => {
            if (currentPage.value > 1) {
                currentPage.value--;
            }
        };

        const goToPage = (page) => {
            currentPage.value = page;
        };

        const fetchLeaderboard = () => {
            const db = getFirestore();
            const leaderboardRef = doc(db, 'leaderboard', 'season_two');

            unsubscribe = onSnapshot(leaderboardRef, (doc) => {
                if (doc.exists()) {
                    const data = doc.data();
                    leaderboard.value = data.leaderboard;
                    currentPool.value = data.currentPool;
                    toBurn.value = data.toBurn;
                    toLock.value = data.toLock;
                    // Sort the leaderboard by points in descending order
                    leaderboard.value.sort((a, b) => b.points - a.points);
                    calculateTotalPages(); // Recalculate total pages when data changes
                }
            });
        };

        const updateTimeUntilMidnight = () => {
            const now = new Date();
            const tokyoTime = new Date(now.toLocaleString('en-US', { timeZone: 'Asia/Tokyo' }));
            const midnight = new Date(tokyoTime);
            midnight.setHours(24, 0, 0, 0);

            const diff = midnight - tokyoTime;
            const hours = Math.floor(diff / 3600000);
            const minutes = Math.floor((diff % 3600000) / 60000);

            timeUntilMidnight.value = `${hours}h ${minutes}m`;
        };

        onMounted(() => {
            fetchLeaderboard();
            updateTimeUntilMidnight();
            timer = setInterval(updateTimeUntilMidnight, 60000);
            calculateTotalPages();
        });

        onUnmounted(() => {
            if (unsubscribe) {
                unsubscribe();
            }
            if (timer) {
                clearInterval(timer);
            }
        });

        watch(leaderboard, () => {
            calculateTotalPages();
        });

        return {
            leaderboard,
            timeUntilMidnight,
            currentPool,
            toBurn,
            toLock,
            paginatedLeaderboard,
            currentPage,
            totalPages,
            nextPage,
            prevPage,
            goToPage,
            itemsPerPage
        };
    },
};
</script>