import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore, Timestamp } from 'firebase/firestore';
import { getDatabase, ref, push, set } from "firebase/database"; 


const firebaseConfig = {
    apiKey: "AIzaSyCe98iPAi93bxgtpQXi3o72hoDr0GFd5lM",
    authDomain: "farthercast-57cf1.firebaseapp.com",
    projectId: "farthercast-57cf1",
    storageBucket: "farthercast-57cf1.appspot.com",
    messagingSenderId: "447501193953",
    appId: "1:447501193953:web:c379b8cceef5cae89adda0",
    measurementId: "G-RWMEH4SV86"
  };

// Initialize firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = getFirestore(app);
const rdb = getDatabase(app)



export { app, auth, firebaseConfig, db, Timestamp, rdb }