import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import Analytics from '../views/Analytics.vue'
import Rewards from '../views/Rewards.vue'
import Calendar  from '../components/Calendar.vue'
import CreateCampaign  from '../views/campaign/Create.vue'
import MyCampaigns  from '../views/campaign/MyCampaigns.vue'
import CurrentCampaigns  from '../views/campaign/Current.vue'
import HomeRewards  from '../views/HomeRewards.vue' 
import Quests  from '../views/Quests.vue' 
import Settings  from '../views/Settings.vue' 
import AdminDashboard  from '../views/admin/AdminDashboard.vue' 
import { getCurrentUser } from 'vuefire'

import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const getCurrentUserDoc = async () => {
  const auth = getAuth();
  const db = getFirestore();

  if (!auth.currentUser) {
    throw new Error('No user is currently signed in');
  }

  const userDocRef = doc(db, 'users', auth.currentUser.uid);
  const userDocSnap = await getDoc(userDocRef);

  if (userDocSnap.exists()) {
    return userDocSnap.data();
  } else {
    throw new Error('No user document found for the current user');
  }
};

const routes = [
  { 
    path: '/', 
    name: 'Home',
    component: Home,
  },
  { 
    path: '/rewards', 
    name: 'HomeRewards',
    component: HomeRewards,
  },
  // { 
  //   path: '/leaderboard', 
  //   name: 'Leaderboard',
  //   component: Leaderboard,
  // },
  {
    path: '/d',
    name: 'Dashboard',
    component: Dashboard,
    children: [
      { path: 'settings', name: 'Settings', component: Settings },
      { path: 'schedule', name: 'Schedule', component: Calendar },
      { path: 'quests', name: 'Quests', component: Quests },
      { path: 'analytics', component: Analytics },
      { path: 'c/create', component: CreateCampaign },
      { path: 'c/my', component: MyCampaigns },
      { path: 'c/current', component: CurrentCampaigns },
      { path: '/d/rewards', component: Rewards },
      { 
        path: 'admin', 
        component: AdminDashboard, 
        meta: { 
          requiresAuth: true,
          requiresAdmin: true 
        },
        beforeEnter: (to, from, next) => {
          // Assuming you have a method to get the current user's document
          getCurrentUserDoc().then(userDoc => {
            if (userDoc && userDoc.isAdmin === true) {
              next();
            } else {
              next('/d/schedule'); // Redirect to dashboard if not admin
            }
          }).catch(error => {
            console.error("Error checking admin status:", error);
            next('/d');
          });
        }
      }
    ],
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async (to, from) => {
  const user = await getCurrentUser()
  if(to.meta.requiresAuth && !user) {
    return { name: 'Home' }
  } 
})


export default router