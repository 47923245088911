<template>
    <template v-if="authIsReady">
        <router-view />
    </template>
    <template v-else>
        <p>loading</p>
    </template>
</template>

<style>
@import './assets/css/fonts.css';
/* Override Bootstrap's body background color */
:root {
    --bs-body-bg: radial-gradient(circle, #7c0dcd, #410780)
}
</style>

<script setup>
import { useStore } from 'vuex'
import { computed, ref, provide, onMounted } from 'vue'

const store = useStore()

const handleClick = () => {
    store.dispatch('logout')
}

const user = computed(() => store.state.user)
const authIsReady = computed(() => store.state.authIsReady)


</script>
