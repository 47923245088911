<template>
  <div v-if="analyticsFid && analyticsData[analyticsFid]">

    <div class="flex-item wide-100">
        <div class="alert alert-primary" role="alert" v-if="!analyticsFilesExist && !checkForAnalytics && userCredits > 5">
            <div class="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
            <div class="d-flex align-items-center mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-4" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
                <span>It looks like you have not generated any analytics reports yet. <br/> Reports cost <strong>3</strong> credits <strong> per linked account.</strong></span>
            </div>
            <button class="btn btn-primary mt-2 mt-md-0" @click="updateAnalytics">Generate Report</button>
            </div>
        </div>
        <div v-else>
            <div class="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
            <div class="d-flex align-items-center mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-4" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
                <span>If your data is outdated, you may need to generate a new report <br/> Reports cost <strong>3</strong> credits <strong> per linked account.</strong></span>
            </div>
            <button class="btn btn-primary mt-2 mt-md-0" @click="updateAnalytics">Generate New Report</button>
            </div>
        </div>
        <div class="alert alert-danger" role="alert" v-if="userCredits < 5">
            <div class="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
            <div class="d-flex align-items-center mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-4" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
                <span>Generating reports cost <strong>5</strong> credits. Top up credits to generate new reports</span>
            </div>
            
            </div>
        </div>
    </div>

    <div class="flex-item wide-100">
      <div class="alert alert-warning d-flex align-items-center" role="alert" v-if="checkForAnalytics">
        <i class="fa-regular fa-clock me-3"></i>
        <div>
          Generating report. This may take upto 5 minutes depending on linked accounts and data. Come back later to check.
        </div>
      </div>
    </div>

    <div class="flex-item wide-100 fc-card p-3">
     Showing analytics data for: 
         <div class="dropdown-container mb-3">
        <select class="form-select" @change="handleAccountChange" v-if="linkedAccounts && linkedAccounts.length > 0">
            <option v-for="(account, index) in linkedAccounts" :key="account.fid" :value="account.fid" :selected="index === 0">
            {{ account.username }}
            </option>
        </select>
    </div>
    </div>

    <!-- First grid: 6 columns per row -->
    <div class="grid-container">
      <div class="grid-item" >
        <div class="d-flex align-items-center justify-content-between">
          <h4 class="mb-0">Likes</h4>
          <i class="fa-solid fa-heart icon"></i>
        </div>
        <!-- <div class="animate-flicker m-3" style="display:block; background-color: #f1f1f1; height: 20px;"></div> -->
        <div class="mt-3" v-if="analyticsData[analyticsFid] && analyticsData[analyticsFid].LikesAndRecastTotals && analyticsData[analyticsFid].LikesAndRecastTotals.past7Days">
          <p class="display-3 mb-0">{{ analyticsData[analyticsFid].LikesAndRecastTotals.past7Days.totalLikes }}</p>
        </div>
        <div class="mt-1" v-if="past7DaysLikesPercentageChange !== null">
          <small :class="getPercentageChangeClass(past7DaysLikesPercentageChange)">{{ past7DaysLikesPercentageChange }}% since last week</small>
        </div>
      </div>

      <div class="grid-item" >
        <div class="d-flex align-items-center justify-content-between">
          <h4 class="mb-0">Casts</h4>
          <i class="fa-brands fa-chromecast icon"></i> 
        </div>
        <div class="mt-3" v-if="analyticsData[analyticsFid] && analyticsData[analyticsFid].LikesAndRecastTotals && analyticsData[analyticsFid].LikesAndRecastTotals.past7Days">
          <p class="display-3 mb-0">{{ analyticsData[analyticsFid].LikesAndRecastTotals.past7Days.totalCasts }}</p>
        </div>
        <div class="mt-1" v-if="past7DaysCastsPercentageChange !== null">
          <small :class="getPercentageChangeClass(past7DaysCastsPercentageChange)">{{ past7DaysCastsPercentageChange }}% since last week</small>
        </div>
      </div>

      <div class="grid-item" >
        <div class="d-flex align-items-center justify-content-between">
          <h4 class="mb-0">Recasts</h4>
          <i class="fa-solid fa-retweet icon"></i>
        </div>
        <div class="mt-3" v-if="analyticsData[analyticsFid] && analyticsData[analyticsFid].LikesAndRecastTotals && analyticsData[analyticsFid].LikesAndRecastTotals.past7Days">
          <p class="display-3 mb-0">{{ analyticsData[analyticsFid].LikesAndRecastTotals.past7Days.totalRecasts }}</p>
        </div>
        <div class="mt-1" v-if="past7DaysRecastsPercentageChange !== null">
          <small :class="getPercentageChangeClass(past7DaysRecastsPercentageChange)">{{ past7DaysRecastsPercentageChange }}% since last week</small>
          
        </div>
      </div>

      <div class="grid-item" >
        <div class="d-flex align-items-center justify-content-between">
          <h4 class="mb-0">Mentions</h4>
          <i class="fa-solid fa-at icon"></i>
        </div>
        <div class="mt-3" v-if="analyticsData[analyticsFid] && analyticsData[analyticsFid].LikesAndRecastTotals && analyticsData[analyticsFid].LikesAndRecastTotals.past7Days">
          <p class="display-3 mb-0">{{ analyticsData[analyticsFid].LikesAndRecastTotals.past7Days.totalMentions }}</p>
        </div>
        <div class="mt-1" v-if="past7DaysMentionsPercentageChange !== null">
          <small :class="getPercentageChangeClass(past7DaysMentionsPercentageChange)">{{ past7DaysMentionsPercentageChange }}% since last week</small>
        </div>
      </div>

      <div class="grid-item" >
        <div class="d-flex align-items-center justify-content-between">
          <h4 class="mb-0">Replies</h4>
          <i class="fa-solid fa-message icon"></i>
        </div>
        <div class="mt-3" v-if="analyticsData[analyticsFid] && analyticsData[analyticsFid].LikesAndRecastTotals && analyticsData[analyticsFid].LikesAndRecastTotals.past7Days">
          <p class="display-3 mb-0">{{ analyticsData[analyticsFid].LikesAndRecastTotals.past7Days.totalReplies }}</p>
        </div>
        <div class="mt-1" v-if="past7DaysRepliesPercentageChange !== null">
          <small :class="getPercentageChangeClass(past7DaysRepliesPercentageChange)">{{ past7DaysRepliesPercentageChange }}% since last week</small>
        </div>
      </div>

      <!-- Add more grid items here if needed -->
    </div>

    <!-- Second grid: 2 columns with the first being 1/5th of the width -->
    <div class="second-grid-container">
      <div class="second-grid-item small-column">
      
        <div class="small-grid-item fc-card" >
          <div class="d-flex align-items-center justify-content-between">
            <h4 class="mb-0">Followers</h4>
            <i class="fa-solid fa-user-plus icon"></i>
          </div>
          <div class="mt-3" v-if="analyticsData[analyticsFid] && analyticsData[analyticsFid].FollowersUnfollowersFidTotals && analyticsData[analyticsFid].FollowersUnfollowersFidTotals.past7Days">
            <p class="display-3 mb-0">{{ analyticsData[analyticsFid].FollowersUnfollowersFidTotals.past7Days.totalFollows }}</p>
            <small>Total: {{ analyticsData[analyticsFid].FollowersUnfollowersFidTotals.past7Days.totalFollowers }} </small>
          </div>
          <div class="mt-1">
            <small :class="getPercentageChangeClass(past7DaysFollowersPercentageChange)">{{ past7DaysFollowersPercentageChange }}% since last week</small>
          </div>
        </div>  

        <div class="small-grid-item fc-card" >
          <div class="d-flex align-items-center justify-content-between">
            <h4 class="mb-0">Unfollowers</h4> 
            <i class="fa-solid fa-user-minus icon"></i>
          </div>
          <div class="mt-3" v-if="analyticsData[analyticsFid] && analyticsData[analyticsFid].FollowersUnfollowersFidTotals && analyticsData[analyticsFid].FollowersUnfollowersFidTotals.past7Days">
            <p class="display-3 mb-0">{{ unfollowers.length }}</p>
          </div>
          <div class="mt-1">
            <small :class="getNegativePercentageChangeClass(past7DaysUnFollowersPercentageChange)">{{ past7DaysUnFollowersPercentageChange }}% since last week</small>
          </div>
        </div>
      </div>
      <div class="second-grid-item large-column">
        <div class="fc-card p-3" >
          <div class="d-flex align-items-center justify-content-between">
            <h4 class="mb-0">Daily Followers</h4>
          </div>
            <div v-if="chartOptions">
                <apexchart
                type="line"
                height="350"
                :options="chartOptions"
                :series="chartSeries"
                ></apexchart>
            </div>
        </div>
      </div>
    </div>


    <!-- followers / unfollowers -->
    <div class="followers-unfollowers-container">
      <div class="followers-column">
        <div class="fc-card p-3">
          <h4>New Followers</h4>
          <p class="text-muted small">New connections bring new opportunities! Consider reaching out to welcome your new followers and start engaging conversations.</p>
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Username</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(follower, index) in paginatedNewFollowers" :key="index">
                <th scope="row">{{ (newFollowersPage - 1) * 10 + index + 1 }}</th>
                <td>
                  <a :href="'https://warpcast.com/' + follower" target="_blank" rel="noopener noreferrer">
                  {{ follower }}
                </a>
                </td>
              </tr>
            </tbody>
          </table>
          <nav aria-label="New Followers pagination">
            <ul class="pagination justify-content-center">
              <li class="page-item" :class="{ disabled: newFollowersPage === 1 }">
                <a class="page-link" href="#" @click.prevent="newFollowersPage--">Previous</a>
              </li>
              <li class="page-item" :class="{ disabled: newFollowersPage === newFollowersPageCount }">
                <a class="page-link" href="#" @click.prevent="newFollowersPage++">Next</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="unfollowers-column">
        <div class="fc-card p-3">
          <h4>Unfollowers</h4>
          <p class="text-muted small">Don't let connections slip away! Try reconnecting with those who unfollowed - a friendly message might rekindle the relationship.</p>
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Username</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(unfollower, index) in paginatedUnfollowers" :key="index">
                <th scope="row">{{ (unfollowersPage - 1) * 10 + index + 1 }}</th>
                <td>
                  <a :href="'https://warpcast.com/' + unfollower" target="_blank" rel="noopener noreferrer">
                    {{ unfollower }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <nav aria-label="Unfollowers pagination">
            <ul class="pagination justify-content-center">
              <li class="page-item" :class="{ disabled: unfollowersPage === 1 }">
                <a class="page-link" href="#" @click.prevent="unfollowersPage--">Previous</a>
              </li>
              <li class="page-item" :class="{ disabled: unfollowersPage === unfollowersPageCount }">
                <a class="page-link" href="#" @click.prevent="unfollowersPage++">Next</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>




  </div>
</template>




<script>
import { defineComponent, ref, onMounted, computed, watch, getCurrentInstance } from 'vue';
import { getFirestore, collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { auth } from '../firebase/config';
import { useStore } from 'vuex'

export default defineComponent({

  setup() {
    const analyticsData = ref({});
    const showDataForMonthOrWeek = ref("week");
    const analyticsFid = ref(null)
    const fidsOfLinkedAccounts = ref([])
    const analyticsFilesExist = ref(false);
    // const checkForAnalytics = ref(true);
    const functions = getFunctions();
    const store = useStore();

    const newFollowersPage = ref(1);
    const unfollowersPage = ref(1);

    const { proxy } = getCurrentInstance()

    onMounted(async () => {
      checkForAnalyticsData();
    });

    const handleAccountChange = (event) => {
        analyticsFid.value = event.target.value;
        // console.log("show analytics for", analyticsFid.value )
    };

    const userCredits = computed(() => {
        if (store.state.userCredits !== null) {
            return parseInt(store.state.userCredits)
        } 
        return null;
    });

    const checkForAnalytics = computed(() => {
        if (store.state.checkForAnalytics !== null) {
            return store.state.checkForAnalytics
        } 
        return null;
    });

    const linkedAccounts = computed(() => {
        if (store.state.linkedAccounts !== null && store.state.linkedAccounts.length > 0) {
            analyticsFid.value = store.state.linkedAccounts[0].fid 
            return store.state.linkedAccounts
        } 
        return null;
    });

    async function checkForAnalyticsData()  {
        try {
        const db = getFirestore();
        const currentUserUid = auth.currentUser.uid;

        async function fetchAnalyticsData() {
          const analyticsRef = collection(db, `users/${currentUserUid}/linkedAccounts`);
          const querySnapshot = await getDocs(analyticsRef);

          let fids = [];

          querySnapshot.forEach(doc => {
            const fid = doc.id;
            const data = doc.data();
            fids.push({ fid, data });
            fidsOfLinkedAccounts.value.push(doc.id)
          });
        

          console.log("analytics fids", fidsOfLinkedAccounts.value)

          return fids;
        }

        async function fetchAnalyticsForFids(fids) {
            console.log("fetching analtics for ", fids )
          for (let i = 0; i < fids.length; i++) {
            const item = fids[i];
            // console.log(`FID: ${item.fid}`);

            if (!analyticsData.value[item.fid]) {
                analyticsData.value[item.fid] = {}; // Initialize the object if it doesn't exist
            }

            try {
              const getLikesAndRecastTotalsForFidsRef = doc(db, `users/${currentUserUid}/linkedAccounts/${item.fid}/analytics/getLikesAndRecastTotalsForFids`);
              const getLikesAndRecastTotalsForFidsRefDoc = await getDoc(getLikesAndRecastTotalsForFidsRef);

              const getFollowersUnfollowersFidTotalsRef = doc(db, `users/${currentUserUid}/linkedAccounts/${item.fid}/analytics/getFollowersUnfollowersFidTotals`);
              const getFollowersUnfollowersFidTotalsRefDoc = await getDoc(getFollowersUnfollowersFidTotalsRef);

              if (getLikesAndRecastTotalsForFidsRefDoc.exists() && getFollowersUnfollowersFidTotalsRefDoc.exists()) {
                    analyticsFilesExist.value = true;
                    store.commit("setCheckForAnalytics", false)
                    console.log("files exist")
                    
                } else {
                    store.commit("setCheckForAnalytics", false)
                    console.log("files dont exust")
                }

              if (getLikesAndRecastTotalsForFidsRefDoc.exists()) {
                // console.log(`Analytics Data for FID ${item.fid}:`, getLikesAndRecastTotalsForFidsRefDoc.data());
                analyticsData.value[item.fid]["LikesAndRecastTotals"] = getLikesAndRecastTotalsForFidsRefDoc.data();
              } else {
                // console.log(`No analytics data found for FID ${item.fid}`);
              }

              if (getFollowersUnfollowersFidTotalsRefDoc.exists()) {
                analyticsData.value[item.fid]["FollowersUnfollowersFidTotals"] = getFollowersUnfollowersFidTotalsRefDoc.data();                
                // console.log(analyticsData.value[item.fid])
              } else {
                // console.log(`No analytics data found for FID ${item.fid}`);
              }
            } catch (error) {
            //   console.error(`Error fetching analytics data for FID ${item.fid}:`, error);
            }
          }
        }

        fetchAnalyticsData().then(fids => {
          return fetchAnalyticsForFids(fids);
        }).catch(error => {
          console.error("Error fetching analytics data:", error);
        });
      } catch (error) {
        console.error('Error fetching analytics summaries:', error);
      }
    }

    async function updateAnalytics() {

      this.$sendAnalyticsEvent('analytics-clicks', {
        'button_id': 'btn_update_analytics',
        'button_name': 'Update Analytics'
      })
       
        store.commit("setCheckForAnalytics", true)

        try {
          console.log("update analytics")
          const updateAnalytics = httpsCallable(functions, 'updateAnalytics')
          // TODO pass in fid and code etc from signer approval
          // use that for worker in cloud function to prepare document for new user and add{}
          // all fields necessary in the firestore ready for app launch
          // then return the function stuff and log in the user.
          // Also find out if user is new and only run this once at the beginning. 
          // next we can add in an update for "cost" for analytics.
          
          const uuid = auth.currentUser.uid
        
          if (fidsOfLinkedAccounts.value && fidsOfLinkedAccounts.value.length > 0) {
            fidsOfLinkedAccounts.value.forEach(fid => {
                console.log(`Updating analytics for fid: ${fid}`);
                updateAnalytics({ fid: fid, uuid: uuid, interval_days: 14 });
            });
            }

          console.log("checking analytics")
          .then((result) => {});
        } catch (err) {
          
        }
    }

    const getPercentageChangeClass = (value) => {
        if (value > 0) {
            return 'text-success';
        } else if (value < 0) {
            return 'text-danger';
        } else {
            return 'text-warning';
        }
    };

     const getNegativePercentageChangeClass = (value) => {
        if (value < 0) {
            return 'text-success';
        } else if (value > 0) {
            return 'text-danger';
        } else {
            return 'text-warning';
        }
    };

   const past7DaysLikesPercentageChange = computed(() => {
      if (analyticsData.value[analyticsFid.value]?.LikesAndRecastTotals?.previous7Days) {
        const past7DaysLikes = analyticsData.value[analyticsFid.value]?.LikesAndRecastTotals?.past7Days.totalLikes;
        const previous7DaysLikes = analyticsData.value[analyticsFid.value]?.LikesAndRecastTotals?.previous7Days.totalLikes;
        if (previous7DaysLikes === 0) {
          return past7DaysLikes > 0 ? '∞' : '0';
        }
        const change = ((past7DaysLikes - previous7DaysLikes) / previous7DaysLikes) * 100;
        return change.toFixed(2);
      }
      return null;
    }); 

    const past7DaysRecastsPercentageChange = computed(() => {
      if (analyticsData.value[analyticsFid.value]?.LikesAndRecastTotals?.previous7Days) {
        const past7DaysRecasts = analyticsData.value[analyticsFid.value]?.LikesAndRecastTotals?.past7Days.totalRecasts;
        const previous7DaysRecasts = analyticsData.value[analyticsFid.value]?.LikesAndRecastTotals?.previous7Days.totalRecasts;
        if (previous7DaysRecasts === 0) {
          return past7DaysRecasts > 0 ? '∞' : '0';
        }
        const change = ((past7DaysRecasts - previous7DaysRecasts) / previous7DaysRecasts) * 100;
        return change.toFixed(2);
      }
      return null; 
    });

    const past7DaysCastsPercentageChange = computed(() => {
      if (analyticsData.value[analyticsFid.value]?.LikesAndRecastTotals?.previous7Days) {
        const past7DaysCasts = analyticsData.value[analyticsFid.value]?.LikesAndRecastTotals?.past7Days.totalCasts;
        const previous7DaysCasts = analyticsData.value[analyticsFid.value]?.LikesAndRecastTotals?.previous7Days.totalCasts;
        if (previous7DaysCasts === 0) {
          return past7DaysCasts > 0 ? '∞' : '0';
        }
        const change = ((past7DaysCasts - previous7DaysCasts) / previous7DaysCasts) * 100;
        return change.toFixed(2);
      }
      return null;
    });

    const past7DaysMentionsPercentageChange = computed(() => {
      if (analyticsData.value[analyticsFid.value]?.LikesAndRecastTotals?.previous7Days) {
        const past7DaysMentions = analyticsData.value[analyticsFid.value]?.LikesAndRecastTotals?.past7Days.totalMentions;
        const previous7DaysMentions = analyticsData.value[analyticsFid.value]?.LikesAndRecastTotals?.previous7Days.totalMentions;
        if (previous7DaysMentions === 0) {
          return past7DaysMentions > 0 ? '∞' : '0';
        }
        const change = ((past7DaysMentions - previous7DaysMentions) / previous7DaysMentions) * 100;
        return change.toFixed(2);
      }
      return null;
    });

    const past7DaysRepliesPercentageChange = computed(() => {
      if (analyticsData.value[analyticsFid.value]?.LikesAndRecastTotals?.previous7Days) {
        const past7DaysReplies = analyticsData.value[analyticsFid.value]?.LikesAndRecastTotals?.past7Days.totalReplies;
        const previous7DaysReplies = analyticsData.value[analyticsFid.value]?.LikesAndRecastTotals?.previous7Days.totalReplies;
        if (previous7DaysReplies === 0) {
          return past7DaysReplies > 0 ? '∞' : '0';
        }
        const change = ((past7DaysReplies - previous7DaysReplies) / previous7DaysReplies) * 100;
        return change.toFixed(2);
      }
      return null;
    });

    const past7DaysFollowersPercentageChange = computed(() => {
      if (analyticsData.value[analyticsFid.value]?.FollowersUnfollowersFidTotals?.previous7Days) {
        const past7DaysFollows = analyticsData.value[analyticsFid.value]?.FollowersUnfollowersFidTotals?.past7Days.totalFollows;
        const previous7DaysFollows = analyticsData.value[analyticsFid.value]?.FollowersUnfollowersFidTotals?.previous7Days.totalFollows;
        if (previous7DaysFollows === 0) {
          return past7DaysFollows > 0 ? '∞' : '0';
        }
        const change = ((past7DaysFollows - previous7DaysFollows) / previous7DaysFollows) * 100;
        return change.toFixed(2);
      }
      return null;
    });

    const past7DaysUnFollowersPercentageChange = computed(() => {
      if (analyticsData.value[analyticsFid.value]?.FollowersUnfollowersFidTotals?.previous7Days) {
        const past7DaysUnFollows = analyticsData.value[analyticsFid.value]?.FollowersUnfollowersFidTotals?.past7Days.totalUnfollows;
        const previous7DaysUnFollows = analyticsData.value[analyticsFid.value]?.FollowersUnfollowersFidTotals?.previous7Days.totalUnfollows;
        if (previous7DaysUnFollows === 0) {
          return past7DaysUnFollows > 0 ? '∞' : '0';
        }
        const change = ((past7DaysUnFollows - previous7DaysUnFollows) / previous7DaysUnFollows) * 100;
        return change.toFixed(2);
      }
      return null;
    });

    const chartOptions = computed(() => {
    if (!analyticsData.value[analyticsFid.value]?.FollowersUnfollowersFidTotals?.past7Days) return null;

    const past7Days = analyticsData.value[analyticsFid.value].FollowersUnfollowersFidTotals.past7Days;

    // Filter out non-date keys and sort dates
    const categories = Object.keys(past7Days)
        .filter(key => key.match(/^\d{2}-\d{2}$/))
        .sort((a, b) => new Date('2023-' + a) - new Date('2023-' + b));

    return {
        chart: {
        id: 'followers-chart',
        type: 'line',
        toolbar: {
            show: false  
        }
        },
        xaxis: {
        categories: categories
        },
        yaxis: {
        min: 0,
        forceNiceScale: true
        },
        legend: {
        position: 'top'
        },
        stroke: {
        curve: 'smooth'
        },
        tooltip: {
        x: {
            format: 'dd/MM'
        }
        }
    };
    });


    const chartSeries = computed(() => {
      if (!analyticsData.value[analyticsFid.value]?.FollowersUnfollowersFidTotals?.past7Days ||
          !analyticsData.value[analyticsFid.value]?.FollowersUnfollowersFidTotals?.previous7Days) return [];

      const past7Days = analyticsData.value[analyticsFid.value].FollowersUnfollowersFidTotals.past7Days;
      const previous7Days = analyticsData.value[analyticsFid.value].FollowersUnfollowersFidTotals.previous7Days;

      // Filter out non-date keys and sort dates
      const pastDates = Object.keys(past7Days)
          .filter(key => key.match(/^\d{2}-\d{2}$/))
          .sort((a, b) => new Date('2023-' + a) - new Date('2023-' + b));

      const previousDates = Object.keys(previous7Days)
          .filter(key => key.match(/^\d{2}-\d{2}$/))
          .sort((a, b) => new Date('2023-' + a) - new Date('2023-' + b));

      const past7DaysData = pastDates.map(date => past7Days[date].new_followers);
      const previous7DaysData = previousDates.map(date => previous7Days[date].new_followers);

      return [
          {
          name: 'Past 7 days',
          data: past7DaysData
          },
          {
          name: 'Previous 7 days',
          data: previous7DaysData
          }
      ];
    });

const newFollowers = computed(() => {
  if (!analyticsFid.value || !analyticsData.value[analyticsFid.value]?.FollowersUnfollowersFidTotals?.past7Days) {
    return [];
  }
  if (analyticsData.value[analyticsFid.value]?.FollowersUnfollowersFidTotals?.past7Days) {
    const past7Days = analyticsData.value[analyticsFid.value].FollowersUnfollowersFidTotals.past7Days;
    const allFollowers = Object.values(past7Days).flatMap(day => day.follower_names || []);
    return [...new Set(allFollowers)]; // Remove duplicates
  }
  return [];
});

const unfollowers = computed(() => {
  if (analyticsData.value[analyticsFid.value]?.FollowersUnfollowersFidTotals?.past7Days) {
    const past7Days = analyticsData.value[analyticsFid.value].FollowersUnfollowersFidTotals.past7Days;
    const allUnfollowers = Object.values(past7Days).flatMap(day => day.unfollower_names || []);
    return [...new Set(allUnfollowers)]; // Remove duplicates
  }
  return [];
});

const paginatedNewFollowers = computed(() => {
  const start = (newFollowersPage.value - 1) * 10;
  return newFollowers.value.slice(start, start + 10);
});

const paginatedUnfollowers = computed(() => {
  const start = (unfollowersPage.value - 1) * 10;
  return unfollowers.value.slice(start, start + 10);
});

const newFollowersPageCount = computed(() => Math.ceil(newFollowers.value.length / 10));
const unfollowersPageCount = computed(() => Math.ceil(unfollowers.value.length / 10));

watch(linkedAccounts, (newAccounts) => {
  if (newAccounts && newAccounts.length > 0) {
    analyticsFid.value = newAccounts[0].fid;
  }
}, { immediate: true });

        // watch(analyticsData, () => {
        // // The chart will automatically update when chartOptions or chartSeries change
        // }, { deep: true });

    return {
      analyticsData,
      analyticsFid,
      chartOptions,
      chartSeries,
      analyticsFilesExist,
      checkForAnalytics,
      updateAnalytics,
      userCredits,
      linkedAccounts,
      handleAccountChange,
      getPercentageChangeClass,
      getNegativePercentageChangeClass,
      past7DaysLikesPercentageChange,
      past7DaysRecastsPercentageChange,
      past7DaysCastsPercentageChange,
      past7DaysMentionsPercentageChange,
      past7DaysRepliesPercentageChange,
      showDataForMonthOrWeek,
      past7DaysFollowersPercentageChange,
      past7DaysUnFollowersPercentageChange,
      newFollowers,
      unfollowers,
      paginatedNewFollowers,
      paginatedUnfollowers,
      newFollowersPage,
      unfollowersPage,
      newFollowersPageCount,
      unfollowersPageCount,
    };
  }
});
</script>

<style>
.icon {
    font-size: 20px
}

.grid-container {
  display: grid;
  gap: 1rem; /* Adjust the gap as needed */
  grid-template-columns: repeat(2, 1fr); /* Default to 2 columns for mobile */
}

/* For tablets and larger screens */
@media (min-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for medium-sized screens */
  }
}

/* For desktops and larger screens */
@media (min-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(5, 1fr); /* 5 columns for large screens */
  }
}


.small-grid-item, .grid-item {
  background-color: white;
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 0.5rem;
}

.second-grid-container {
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
  grid-template-columns: 1fr; /* Default to single column for mobile */
}

.small-column {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns for small grid items on mobile */
  gap: 1rem;
}

.small-column .fc-card {
  margin-bottom: 0; /* Remove bottom margin as we're using grid gap */
}

.large-column .fc-card {
  height: auto; /* Allow height to adjust to content on mobile */
}


/* Add this to your <style> section */
.followers-unfollowers-container {
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
  grid-template-columns: 1fr; /* Default to single column for mobile */
}

@media (min-width: 768px) {
  .followers-unfollowers-container {
    grid-template-columns: 1fr 1fr; /* Two columns for larger screens */
  }
}

.followers-column, .unfollowers-column {
  overflow-x: auto; /* Allow horizontal scrolling if table is too wide */
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

/* For tablets and larger screens */
@media (min-width: 768px) {
  .second-grid-container {
    grid-template-columns: 1fr 4fr; /* Revert to original layout for larger screens */
  }

  .small-column {
    display: block; /* Revert to single column for small grid items */
  }

  .small-column .fc-card {
    margin-bottom: 1rem; /* Restore bottom margin for larger screens */
  }

  .large-column .fc-card {
    height: 100%; /* Restore full height for larger screens */
  }
}
@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
.animate-flicker {
   -webkit-animation: flickerAnimation 1s infinite;
   -moz-animation: flickerAnimation 1s infinite;
   -o-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
}
</style>
