<template>
  <div>
    <div v-if="currentPool">
      <p>Current Pool: {{ currentPool }}</p>
      <p>Total Distributed Reward: {{ totalPossibleReward }}</p>
      <p>Remaining Undistributed Amount: {{ remainingPoolAmount }}</p>
    </div>
    <table v-if="!isLoading && numberedGroupedLeaderboard.length">
      <thead>
        <tr>
          <th>Select</th>
          <th>#</th>
          <th>Rank</th>
          <th>Username</th>
          <th>Points</th>
          <th>Reward</th>
          <th>ETH Address</th>
          <th>Select</th>
          <th>#</th>
          <th>Rank</th>
          <th>Username</th>
          <th>Points</th>
          <th>Reward</th>
          <th>ETH Address</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="pair in numberedGroupedLeaderboard" :key="pair[0].numberInList">
          <template v-for="entry in pair" :key="entry.numberInList">
            <td><input type="checkbox" :id="'select-' + entry.numberInList" /></td>
            <td><strong>{{ entry.numberInList }}</strong></td>
            <td>{{ entry.rank }}</td>
            <td>{{ entry.username }}</td>
            <td>{{ entry.points }}</td>
            <td>{{ entry.possible_reward }}</td>
            <td>{{ entry.eth_address }}</td>
          </template>
        </tr>
      </tbody>
    </table>
    <p v-else-if="isLoading">Loading leaderboard data...</p>
    <p v-else>No leaderboard data available.</p>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { getFirestore, doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { app, firebaseApp, db, Timestamp, auth } from '../../firebase/config';
export default {
  setup() {
    const leaderboardData = ref([]);
    const currentPool = ref(0);
    const isLoading = ref(true);

const fetchLeaderboardData = async () => {
 

  if (!auth.currentUser) {
    console.error('User not authenticated');
    return;
  }

  const leaderboardRef = doc(db, 'leaderboard', 'season_one');
  
  try {
    const docSnap = await getDoc(leaderboardRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      currentPool.value = data.currentPool || 0;
      leaderboardData.value = Object.values(data.leaderboard || {});
      
      const usernames = leaderboardData.value.map(entry => entry.username);
      const usersRef = collection(db, 'users');
      
      const batchSize = 30;
      const userDataPromises = [];
      for (let i = 0; i < usernames.length; i += batchSize) {
        const batch = usernames.slice(i, i + batchSize);
        const q = query(usersRef, where('username', 'in', batch));
        userDataPromises.push(getDocs(q));
      }
      
      const userSnapshots = await Promise.all(userDataPromises);
      
      const userData = {};
        userSnapshots.forEach(snapshot => {
        snapshot.forEach(doc => {
            const user = doc.data();
            // console.log('User data for', user.username, ':', user);
            // console.log('Verified addresses:', user.verified_addresses);
            const ethAddresses = user.verified_addresses?.eth_addresses || [];
            const primaryEthAddress = ethAddresses.length > 0 ? ethAddresses[0] : 'N/A';
            // console.log('Primary ETH address:', primaryEthAddress);
            userData[user.username] = primaryEthAddress;
        });
        });
      
      leaderboardData.value = leaderboardData.value.map(entry => {
        const ethAddress = userData[entry.username] || 'N/A';
        // console.log('Assigning ETH address for', entry.username, ':', ethAddress);
        return {
          ...entry,
          eth_address: ethAddress
        };
      });
      
      if (leaderboardData.value.length !== 40) {
        console.warn(`Expected 40 entries, but got ${leaderboardData.value.length}`);
      }
      calculatePossibleRewards();
    } else {
      console.log('No such document!');
    }
  } catch (error) {
    console.error('Error fetching leaderboard data:', error);
  } finally {
    isLoading.value = false;
  }
};





const calculatePossibleRewards = () => {
  const totalPoints = leaderboardData.value.reduce((sum, entry) => sum + entry.points, 0);
  let undistributedAmount = currentPool.value;
  
  leaderboardData.value = leaderboardData.value
    .map((entry, index) => {
      const reward = Math.floor(entry.points / totalPoints * currentPool.value);
      undistributedAmount -= reward;
      return {
        ...entry,
        possible_reward: reward
      };
    })
    .sort((a, b) => a.rank - b.rank);

  // Add undistributed amount to the first result
  if (leaderboardData.value.length > 0) {
    leaderboardData.value[0].possible_reward += undistributedAmount;
  }
};

    const groupedLeaderboard = computed(() => {
  const groups = [];
  for (let i = 0; i < leaderboardData.value.length; i += 2) {
    groups.push(leaderboardData.value.slice(i, i + 2));
  }
  return groups;
});

const numberedGroupedLeaderboard = computed(() => {
  const pairs = [];
  for (let i = 0; i < leaderboardData.value.length; i += 2) {
    const pair = leaderboardData.value.slice(i, i + 2).map((entry, pairIndex) => ({
      ...entry,
      numberInList: i + pairIndex + 1
    }));
    pairs.push(pair);
  }
  return pairs;
});

const totalPossibleReward = computed(() => {
  return leaderboardData.value.reduce((sum, entry) => sum + entry.possible_reward, 0);
});

const remainingPoolAmount = computed(() => {
  return currentPool.value - totalPossibleReward.value;
});

    onMounted(() => {
      fetchLeaderboardData();
    });

    return {
      groupedLeaderboard,
      numberedGroupedLeaderboard,
      currentPool,
      totalPossibleReward,
      remainingPoolAmount,
      isLoading
    };
  }
}
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}
</style>