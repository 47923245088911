import { createStore } from 'vuex'
import { ref } from 'vue'
import { auth, db, rdb } from '../firebase/config'
import { signInWithCustomToken, signOut, onAuthStateChanged, getAuth, getAdditionalUserInfo } from 'firebase/auth'
import { collection, getDoc, doc, addDoc, deleteDoc,setDoc, updateDoc, getDocs, query, where, onSnapshot, Timestamp } from "firebase/firestore";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { ref as rdbref, push, set } from "firebase/database"; 


// const db = getFirestore(); // Initialize Firestore instance

const events = ref([]);
const functions = getFunctions();

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

const store = createStore({
  state: {
    user: null,
    authIsReady: false,
    userCredits: null,
    deleteEvent: false,
    viewableAccounts: [],
    events: [],
    newEventDateTime: [],
    linkedAccounts: [],
    loadingAccountSpinner: false,
    modalButtonLoading: false,
    isEventModalVisible: false,
    error: "",
    userDisplayName: null,
    userPFP: null,
    addEventError: "",
    pending_event: {
      description: '',
      title: '',
      channel: '',
      account: '',
      startDate: '',
      startTime: '',
      imageURL: null,
      imageData: null,
      castCost: 0,
      modalButtonLoading: false,
      isButtonDisabled: false
    },
    loginStepStatus: null,
    checkForAnalytics: true
  },
  mutations: {
    updatePendingEvent(state, { fieldName, value }) {
      state.pending_event[fieldName] = value;
    },
    updateLoginStepStatus(state, payload) {
      state.loginStepStatus = payload
    },
    setUser(state, payload) {
      state.user = payload
      // console.log("user state changed:", state.user)
    },
    setAddEventError(state, payload) {
      state.addEventError = payload
      // console.log("Event Error", state.addEventError)
    },
    setLoadingAccountSpinner(state, value) {
      state.loadingAccountSpinner = value;
    },
    setCheckForAnalytics(state, value) {
      state.checkForAnalytics = value;
    },
    setAuthIsReady(state, payload) {
      state.authIsReady = payload
    },
    setUserCredits(state, credits) { // New mutation to update user's credits
      state.userCredits = credits
    },
    setUserDisplayName(state, payload) { // New mutation to update user's credits
      state.userDisplayName = payload
    },
    setUserPFP(state, payload) { // New mutation to update user's credits
      state.userPFP = payload
    },
    setEventData(state, payload) {  
      state.events = payload;
      // console.log("setting event data", payload)
      // console.log("events are", state.events)
    },
    setViewableAccounts(state, payload) {  
      state.viewableAccounts = payload;
      // console.log("viewableAccounts", state.viewableAccounts)
    },
    setLinkedAccounts(state, accounts) {
      state.linkedAccounts = accounts; 
    },
    setNewEventDateTime(state, payload) {
      state.newEventDateTime = payload
    },
    showModalButtonLoading(state, payload) {
      state.modalButtonLoading = payload;
    },
    setError(state, errorMessage) {
      state.error = errorMessage;
    },
  },
  actions: {
    //   async login(context, { email }) {

    //   const auth = getAuth();
    //   store.commit('setError', null)

    //   console.log("login action")

    //   const actionCodeSettings = {
    //     url: 'http://localhost:8080/login', // https://farthercast.xyz/login ~~ http://localhost:8080/login
    //     handleCodeInApp: true,
    //   };
    // },
    async handleLogin({ commit }, data) {  
        
        const code = data.signer_uuid;
        const user = data.user
        const fid = data.user.fid
    

      try {

        const createUserFromCode = httpsCallable(functions, 'createUserFromCode')
        // TODO pass in fid and code etc from signer approval
        // use that for worker in cloud function to prepare document for new user and add{}
        // all fields necessary in the firestore ready for app launch
        // then return the function stuff and log in the user.
        store.commit("updateLoginStepStatus", "Generating authentication")
        createUserFromCode({ code: code, siwn_user: user })
        .then((result) => {
          store.commit("updateLoginStepStatus", "Logging in")
            signInWithCustomToken(auth, result.data.customToken)
            .then((result) => {
                  // Post into Realtime Database
              
                  const tasksRef = rdbref(rdb, 'tasks');
                  const newTaskRef = push(tasksRef); // Generate a new unique task ID

                  store.commit("updateLoginStepStatus", "Checking for pending airdrop/credits")

                  set(newTaskRef, {
                      type: "check_pending_tips",
                      fid: fid,
                      uuid: code,
                      uid: result.user.uid
                  }).then(() => {
                      // Redirect to schedule page after posting to the database
                      store.commit("updateLoginStepStatus", "Complete")
                      window.location.href = "/d/schedule";
                  }).catch((error) => {
                      console.error("Error writing to database: ", error);
                  });
              });
        });

      } catch (err) {
        console.error('Error signing in:', err);
      } finally {
        // showLoader.value = false;
      }
    },

    async logout(context) {
      // console.log("logout action")

      await signOut(auth)
      context.commit('setUser', null)
    },
    async fetchUserData({ commit }) { 
      try {
        const currentUser = auth.currentUser;
        if (currentUser) {
          const docRef = doc(db, 'users', currentUser.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            // console.log(currentUser.uid, docSnap.data())
            const credits = docSnap.data().credits;
            const displayName = docSnap.data().display_name;
            const userPFP = docSnap.data().pfp_url;
            await commit('setUserCredits', credits);
            await commit('setUserDisplayName', displayName);
            await commit('setUserPFP', userPFP);
            // TODO Add user points here. Make 0 if null

            // Listen for real-time updates to the user's credits
            onSnapshot(docRef, (snapshot) => {
              const updatedCredits = snapshot.data().credits;
              commit('setUserCredits', updatedCredits);
            });
          } else {
            // console.log('No such document for credits!');
          }
        }
      } catch (error) {
        // console.log('Error getting document:', error);
        // Handle error
      }
    },

    async addEventData() {
      const accounts = store.state.viewableAccounts;
    
      // If there are no viewable accounts, clear events and return
      if (Object.values(accounts).every(value => !value)) {
        store.commit('setEventData', []);
        return;
      }
    
      // const db = getFirestore();
      const currentUser = auth.currentUser;
    
      const fetchedEvents = [];
    
      // Get the current date/time
      const currentDate = new Date();
    
      // Use Promise.all to wait for all asynchronous operations to complete
      await Promise.all(
        // Map over each account
        Object.keys(accounts).map(async accountId => {
          if (accounts[accountId]) {
            if (currentUser) {
              const userUUID = currentUser.uid;
              const eventsRef = collection(
                db,
                "users",
                userUUID,
                "linkedAccounts",
                accountId,
                "pending_casts"
              );
    
              // Create a query to get events for the specified account
              const q = query(eventsRef, where("start", ">", currentDate)); // Filter for events starting in the future
    
              // Fetch events for the account
              const snapshot = await getDocs(q);
    
              // Iterate over the snapshot to extract event data
              snapshot.forEach(doc => {
                const eventData = {
                  id: doc.id, // Use Firestore document ID as event ID
                  title: doc.data().title,
                  extra: doc.data().extra,
                  text: doc.data().text,
                  accountId: accountId,
                  channel: doc.data().channel,
                  start: formatDate(doc.data().start.toDate()), // Format start date
                  end: formatDate(doc.data().end.toDate()), // Format end date
                  calendarId: doc.data().calendarId,
                  image: doc.data().image,
                };
    
                fetchedEvents.push(eventData);
              });
            } else {
              // console.log("No user signed in.");
            }
          }
        })
      );

      
    
      // Update the events state with fetched events
      store.commit('setEventData', fetchedEvents);
      
    },

    async updateEvent({ commit }, { id, fid, update_cast_submit }) {
      try {
        const currentUser = auth.currentUser;

    
        if (currentUser) {
          const docRef = doc(db, "users", currentUser.uid, "linkedAccounts", fid, "pending_casts", id);
          await updateDoc(docRef, update_cast_submit);
    
          store.dispatch('addEventData');
        } else {
          // console.log("user not logged in");
        }
      } catch (error) {
        console.error('Error updating document: ', error);
      }
    },
    
 
    async deleteEvent({commit}, {id, fid}) {

      try {
        const currentUser = auth.currentUser; 

        // console.log("delete event: ", id, "for user", currentUser.uid, "using account:", fid)
        if (currentUser) {
          await deleteDoc(doc(db, "users", currentUser.uid, "linkedAccounts", fid, "pending_casts", id));

          store.dispatch('addEventData')
         
        } else {
          // console.log("user not logged in")
        }
      } catch (error) {
        // console.error('Error deleteing document: ', error);
       
      }
    },

    async storeEvent(context, pending_cast) {
      try {
        const currentUser = auth.currentUser; 
          if (currentUser) {            
            try {
                let isFirstIteration = true;

                for (const accountId of pending_cast.account) {
                  const parentDocRef = doc(db, `users/${currentUser.uid}/linkedAccounts/${accountId}`);
                  const collectionRef = collection(parentDocRef, 'pending_casts');
                  const parentDocSnap = await getDoc(parentDocRef);

                  if (parentDocSnap.exists()) {
                    const calendarId = parentDocSnap.data().calendarId || "account_99";

                    // console.log("pending_cast.image_url ", pending_cast.image )
                    const image_cost = isFirstIteration && pending_cast.image !== '';

                    // console.log("local image cost", image_cost)
                    
                    const nEvent = {
                      title: pending_cast.title,
                      text: pending_cast.text,
                      image: pending_cast.image,
                      channel: pending_cast.channel,
                      start: pending_cast.start,
                      end: pending_cast.end,
                      timezone: pending_cast.timezone,                      
                      calendar_id: calendarId,
                      image_cost: image_cost                      
                    };

                    await addDoc(collectionRef, nEvent);

                    if (isFirstIteration) {
                      isFirstIteration = false;
                    }
                    // store.dispatch('addEventData');
                  } else {
                    console.error(`Parent document for account ${accountId} does not exist.`);
                  }
                }
              
            } catch (error) {
              // console.log("error", error)
            }

          } else {
            console.error('User is not authenticated.');
            // Handle the case where the user is not authenticated
          }
        } catch (error) {
          if (error.code === 'permission-denied') {
            if (error.message.includes('insufficient')) {
              store.commit('setAddEventError', "You currently have 0 credits")
            } else {
              // Generic error message for other permission-denied cases
              console.error('Operation not allowed.');
            }
          } else {
            console.error('Error:', error);
          }
        }
    },

    async listenForLinkedAccountsChanges({ commit }) {
      const currentUser = auth.currentUser;
    
      if (currentUser) {
        const userUUID = currentUser.uid;
        const linkedAccountsRef = collection(db, "users", userUUID, "linkedAccounts");
    
        // Listen for changes to the linked accounts collection
        const unsubscribe = onSnapshot(linkedAccountsRef, (snapshot) => {
          const linkedAccounts = snapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              fid: data.fid,
              username: data.username,
            };
          });
          commit('setLinkedAccounts', linkedAccounts); // Commit linkedAccounts mutation
        });
    
        return unsubscribe;
      } else {
        // console.log("No user signed in.");
      }
    }

  },
  getters: {
    linkedAccounts: state => state.linkedAccounts,
    newEventDateTime: state => state.newEventDateTime,
    isEventModalVisible: state => state.isEventModalVisible,
    modalButtonLoading: state => state.modalButtonLoading,
    loginStepStatus: state => state.loginStepStatus
  }
})

const unsub = onAuthStateChanged(auth, (user) => {
  store.commit('setAuthIsReady', true)
  store.commit('setUser', user)
  // console.log("auth changes")
  if (user) {
    // console.log(user)
    store.dispatch('fetchUserData') // Fetch user's credits when user is authenticated
  }
  unsub()
})

export default store
