<script setup>
import { ref, watch, onMounted, computed, defineEmits } from "vue";
import { useStore } from 'vuex'
import { ScheduleXCalendar } from "@schedule-x/vue";
import { createCalendar, viewWeek, viewDay } from "@schedule-x/calendar";
import { createCurrentTimePlugin } from "@schedule-x/current-time";
import * as bootstrap from "bootstrap";

import "@schedule-x/theme-default/dist/index.css";


const refreshKey = ref(0);
const store = useStore()
const currentDayNumber = (new Date()).getDay();

const emit = defineEmits(['scheduleCast']);


// Define calendarApp
const calendarApp = createCalendar({
  views: [viewWeek],
  firstDayOfWeek: currentDayNumber,
  weekOptions: {
    gridHeight: 1400,
  },
  defaultView: viewWeek.name,
  events: [], // Initialize events as an empty array
  plugins: [createCurrentTimePlugin()],
  calendars: {
    account_1: {
      colorName: "account_1",
      lightColors: {
        main: "#77d8d8",
        container: "#d5f4f4",
        onContainer: "#285959",
      },
      darkColors: {
        main: "#a2c0c0",
        onContainer: "#e6fafa",
        container: "#426a6a",
      },
    },
    account_2: {
      colorName: "account_2",
      lightColors: {
        main: "#f9845c",
        container: "#ffd8c6",
        onContainer: "#5a200a",
      },
      darkColors: {
        main: "#ffc6a2",
        onContainer: "#ffe6d8",
        container: "#a25842",
      },
    },
    account_3: {
      colorName: "account_3",
      lightColors: {
        main: "#70db70",
        container: "#d9ffd9",
        onContainer: "#2e6c2e",
      },
      darkColors: {
        main: "#a2c0a2",
        onContainer: "#e6fde6",
        container: "#426a42",
      },
    },
    account_4: {
      colorName: "account_4",
      lightColors: {
        main: "#9f71f9",
        container: "#ead2ff",
        onContainer: "#380059",
      },
      darkColors: {
        main: "#c0a2ff",
        onContainer: "#e6d8ff",
        container: "#5942a2",
      },
    },
    account_5: {
      colorName: "account_5",
      lightColors: {
        main: "#f9d26a",
        container: "#fff5cc",
        onContainer: "#594b00",
      },
      darkColors: {
        main: "#ffeba2",
        onContainer: "#fff5de",
        container: "#a29742",
      },
    },
    account_6: {
      colorName: "account_6",
      lightColors: {
        main: "#f96c86",
        container: "#ffd2e1",
        onContainer: "#590023",
      },
      darkColors: {
        main: "#ffc0d9",
        onContainer: "#ffdee9",
        container: "#a2426a",
      },
    },
    account_7: {
      colorName: "account_7",
      lightColors: {
        main: "#30336b",
        container: "#c7ecee",
        onContainer: "#130f40",
      },
      darkColors: {
        main: "#d9a2ff",
        onContainer: "#ede6ff",
        container: "#6a42a2",
      },
    },
    account_8: {
      colorName: "account_8",
      lightColors: {
        main: "#f0932b",
        container: "#f9ca24",
        onContainer: "#594100",
      },
      darkColors: {
        main: "#ffc8a2",
        onContainer: "#ffe8de",
        container: "#a25f42",
      },
    },
    account_9: {
      colorName: "account_9",
      lightColors: {
        main: "#6cd3f9",
        container: "#d2f1ff",
        onContainer: "#004059",
      },
      darkColors: {
        main: "#a2d9ff",
        onContainer: "#deedff",
        container: "#426aa2",
      },
    },
    account_10: {
      colorName: "account_10",
      lightColors: {
        main: "#b2bec3",
        container: "#dfe6e9",
        onContainer: "#636e72",
      },
      darkColors: {
        main: "#ffcca2",
        onContainer: "#ffe6de",
        container: "#a26a42",
      },
    },
  },
  callbacks: {
    onRangeUpdate(range) {
      // console.log("onRangeUpdate", range);
    },

    onEventUpdate(event) {
      // console.log("onEventUpdate", event);
    },

    async onEventClick(event) {
      // console.log("onEventClick", event);
      emit('editORDeleteCast', event);
    },

    onClickDateTime(dateTime) {
      // console.log("onClickDateTime", dateTime);
      emit('scheduleCast', dateTime);
    }
  },
});



// Update calendarApp.events when events prop changes
watch(
  () => store.state.events,
  (newEvents, oldEvents) => {
      calendarApp.events.set(newEvents);     
      store.commit('setLoadingAccountSpinner', false);
  },{ deep: true }
);

watch(
  () => store.state.viewableAccounts,
  (newValue, oldValue) => {
   store.dispatch('addEventData')
  },
  { deep: true }
);
</script>

<template>

    <ScheduleXCalendar
      :key="refreshKey"
      :calendar-app="calendarApp"
    />
</template>
