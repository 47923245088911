<template>

<div class="container-custom">

    <div class="offcanvas-lg">
        <sidebar /> 
        
    </div>
    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasSidebar" aria-labelledby="offcanvasSidebarLabel">
        <div class="offcanvas-header">
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <sidebar @close-offcanvas="closeOffcanvas"  /> 

        </div>
    </div>
    <div class="content">
        <div class="nav"> 
          <navbar />
        </div>
        <linkedAccounts v-if="$route.name === 'Schedule'"/>

        <div class="flex-container">

            <div class="flex-item wide-full">
              <!-- <calendarApp /> -->
              <router-view @scheduleCast="showScheduleCastModal" @editORDeleteCast="showEditOrDeleteCastModal"></router-view>
            </div>
<!-- 
            <div class="flex-item fc-card">2</div>
            <div class="flex-item fc-card">3</div>
            <div class="flex-item fc-card wide-75">4</div>
            <div class="flex-item fc-card">5</div>
            <div class="flex-item fc-card wide-3">6</div>
            <div class="flex-item fc-card">7</div>
            <div class="flex-item fc-card">8</div>
            <div class="flex-item fc-card wide-full">9</div> -->
        </div>
    </div>
</div>
<div class="offcanvas offcanvas-end" data-bs-backdrop="static" tabindex="-1" id="newPendingCastOffCanvas" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    <h5 id="offcanvasRightLabel"><i class="fa-regular fa-clock"></i> Schedule Cast</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  
  <div class="offcanvas-body">
    <form @submit.prevent="">

    <!-- cast text -->
      <div class="row mb-3">
        <div class="col">
         <p><span class="cast_textarea" role="textbox" id="pending_text" ref="pending_text" @input="handleInputChange('pending_text', $event.target.innerText)" contenteditable></span></p>
         <small class="p-2" v-if="isDescriptionLong">First 320 characters will be visible on the timeline</small>
        </div>
        <div id="imagePreview">
          <div v-if="imageURL" id="removeImage" @click="removeImage">
            <i class="fa-solid fa-circle-xmark"></i>
          </div>
          <div v-if="imageData!=null">
            <span class="spinner-border spinner-border-sm" role="status"></span> Loading
          </div>
          <div v-if="imageURL">
            <img class="preview" :src="imageURL">
          </div>
          <i class="fa-solid fa-image pr-2" id="imageUpload" @click="imageUpload"></i>
          
          <input type="file" ref="imageUploadField" style="display: none" @change="previewImage" accept="image/*">
        </div>
      </div>
      

    <!-- title -->
      <div class="row mb-3">
        <div class="col">
          <input type="text" class="form-control" v-model="pending_cast.title" id="pending_title" placeholder="Title" @input="handleInputChange('pending_title', $event.target.value)" required>
          <!-- <small>This title is only for the calendar</small> -->
        </div>
      </div>

    <!-- Acount-->
      <div class="row mb-3">
        <v-select 
          multiple 
          v-model="pending_cast.account" 
          :placeholder="placeholder" 
          :options="accountOptions.map(account => ({ label: account.username, value: account.fid }))"
        />
      </div>

    <!-- Channel -->
      <div class="row mb-3">
        <ChannelsLiveSearch inputId="pending_channel" @channelName="handleChannelName"/>
      </div>


    <!-- TIME AND DATE-->
      <div class="row mb-3">
        <div class="col">
          <div class="input-group">
            <input
              type="date"
              class="form-control"
              id="startDate"
              v-model="pending_cast.startDate"
            />
          </div>
        </div>
        <div class="col">
          <div class="input-group">
            <input
              type="time"
              class="form-control"
              id="startTime"
              v-model="pending_cast.startTime"
            />
          </div>
        </div>
      </div>

    <!-- Submit -->
    <div class="d-grid gap-2">
      <p class="alert alert-primary text-center" role="alert">
      This cast will cost <i class="fa-solid fa-coins pr-3"></i>  <strong>{{ castCost }}</strong>
      </p>

      <button type="submit" class="btn btn-lg btn-primary" @click="handleScheduleNewCast()" :disabled="isButtonDisabled">
        <span class="spinner-border spinner-border-sm" role="status" v-if="modalButtonLoading"></span>
        <span v-if="!modalButtonLoading">Schedule</span>
      </button>
    </div>

    </form>
  </div>
</div>



<!-- Edit/Delete -->
<div class="offcanvas offcanvas-end" data-bs-backdrop="static" tabindex="-1" id="editPendingCastOffCanvas" aria-labelledby="offcanvasEditLabel">
  <div class="offcanvas-header">
    <h5 id="offcanvasEditLabel"><i class="fa-regular fa-clock"></i> Edit Cast</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  
  <div class="offcanvas-body">
    <form @submit.prevent="">

    <!-- cast text -->
      <div class="row mb-3">
        <div class="col">
         <p><span class="cast_textarea" role="textbox" id="edit_pending_text" ref="edit_pending_text" @input="handleInputChange('edit_pending_text', $event.target.innerText)" contenteditable>{{ pending_cast.text }}</span></p>
         <small class="p-2" v-if="isDescriptionLong">First 320 characters will be visible on the timeline</small>
        </div>
        <div id="imagePreview">
          <div v-if="imageURL" id="removeImage" @click="removeImage">
            <i class="fa-solid fa-circle-xmark"></i>
          </div>
          <div v-if="imageData!=null">
            <span class="spinner-border spinner-border-sm" role="status"></span> Loading
          </div>
          <div v-if="imageURL">
            <img class="preview" :src="imageURL">
          </div>
          <i class="fa-solid fa-image pr-2" id="imageUpload" @click="imageUpload"></i>
          
          <input type="file" ref="imageUploadField" style="display: none" @change="previewImage" accept="image/*">
        </div>
      </div>
      
    <!-- title -->
      <div class="row mb-3">
        <div class="col">
          <input type="text" class="form-control" v-model="pending_cast.title" id="edit_pending_title" placeholder="Title" @input="handleInputChange('edit_pending_title', $event.target.value)" required>
        </div>
      </div>

    <!-- Account -->
      <div class="row mb-3">
        <v-select 
          multiple 
          v-model="pending_cast.account" 
          :placeholder="placeholder" 
          :options="accountOptions.map(account => ({ label: account.username, value: account.fid }))"
        />
      </div>

    <!-- Channel -->
      <div class="row mb-3">
        <ChannelsLiveSearch inputId="edit_channel" @channelName="handleChannelName"/>
      </div>

    <!-- TIME AND DATE -->
      <div class="row mb-3">
        <div class="col">
          <div class="input-group">
            <input
              type="date"
              class="form-control"
              id="edit_startDate"
              v-model="pending_cast.startDate"
            />
          </div>
        </div>
        <div class="col">
          <div class="input-group">
            <input
              type="time"
              class="form-control"
              id="edit_startTime"
              v-model="pending_cast.startTime"
            />
          </div>
        </div>
      </div>

    <!-- Submit and Delete -->
    <div class="d-grid gap-2">
      <p class="alert alert-primary text-center" role="alert">
      This cast will cost <i class="fa-solid fa-coins pr-3"></i>  <strong>{{ castCost }}</strong>
      </p>

      <button type="button" class="btn btn-lg btn-primary" @click="handleUpdateCast(pending_cast.id, pending_cast.account)" :disabled="isButtonDisabled">
        <span class="spinner-border spinner-border-sm" role="status" v-if="modalButtonLoading"></span>
        <span v-if="!modalButtonLoading">Update</span>
      </button>
      <button type="button" class="btn btn-lg btn-danger" @click="handleDeleteCast(pending_cast.id, pending_cast.account)" :disabled="isButtonDisabled">
        <span v-if="!modalButtonLoading">Delete</span>
      </button>
    </div>

    </form>
  </div>
</div>


</template>

<script>
import { ref, computed, watch, onMounted, nextTick } from "vue";
import calendarApp from "../components/Calendar.vue";
import sidebar from "../components/Sidebar.vue";
import linkedAccounts from "../components/LinkedAccounts.vue";
import navbar from "../components/Navbar.vue";
import { useStore, mapState } from 'vuex'
import * as bootstrap from "bootstrap";
import { uuid } from 'vue-uuid'; 
import ChannelsLiveSearch from '../components/ChannelsLiveSearch.vue';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

// import moment from 'moment-timezone';


import { app, firebaseApp, db, Timestamp, auth } from '../firebase/config';
import { collection, getDocs, doc, getDoc, updateDoc, collectionGroup } from 'firebase/firestore';

import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';
const storage = getStorage(app);



export default {
  name: "Dashboard",
  components: {
    calendarApp,
    sidebar,
    navbar,
    linkedAccounts,
    ChannelsLiveSearch,
    vSelect
  },
  props: {
    pending_cast: {
      account: []
    },
    placeholder: {
      type: String,
      default: "Account"
    },
  },
  selectedFids: {
    get() {
      return this.pending_cast.account;
    },
    set(fids) {
      this.pending_cast.account = fids.map(fid => {
        const account = this.accountOptions.find(account => account.fid === fid);
        return account ? fid : null;
      }).filter(fid => fid !== null);
    }
  },
  computed: {
    ...mapState({
      accountOptions: state => state.linkedAccounts
    })
  },
  methods: {
    closeOffcanvas() {
      const offcanvasElement = document.getElementById('offcanvasSidebar');
      if (offcanvasElement) {
        const bsOffcanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
        if (bsOffcanvas) {
          bsOffcanvas.hide();
        }
      }
    }
  },

  setup() {


    const calendarEvents = ref([]);
    const store = useStore()
    const imageUploadField = ref(null);
    const imageURL = ref(null)
    const imageData = ref(null)
    const castCost = ref(1)
    const isDescriptionLong = computed(() => pending_cast.value.text.length > 350);

    const newEvent = ref({
      title: "",
      channel: "",
      account: "",
      description: "",
    });





    /* Schedule Cast Data */ 

    const pending_cast = ref({
      title: '',
      text: '',
      account: '',
      image_url: '',
      channel: '',
      startDate: '',
      startTime: ''
    })
  

    watch(pending_cast, (newVal) => {
      // Reset castCost to 1
      castCost.value = 1;

      // Increment for each additional account beyond the first
      if (Array.isArray(newVal.account) && newVal.account.length > 1) {
        castCost.value += newVal.account.length - 1;
      }

      // Increment for each additional channel beyond the first
      if (Array.isArray(newVal.channel) && newVal.channel.length > 1) {
        castCost.value += newVal.channel.length - 1;
      }

      // Increment if there is an image_url
      if (newVal.image_url) {
        castCost.value += 1;
      }

      // console.log('pending_cast changed:', newVal, 'castCost:', castCost.value);
    }, { deep: true });

    const resetPendingCast = async () => {
      // Reset the reactive object
      Object.keys(pending_cast.value).forEach(key => {
        pending_cast.value[key] = '';
      });

      const pending_text = document.getElementById('pending_text');
      if (pending_text) {
        pending_text.innerText = '';
      } 

      const pending_channel = document.getElementById('pending_channel');
      if (pending_channel) {
        pending_channel.value = '';
      } 

      const edit_channel = document.getElementById('edit_channel');
      if (edit_channel) {
        edit_channel.value = '';
      } 
    };
    

   

    const handleInputChange = (field, value) => {
      // console.log("ch", isChannelFilled.value, " ac", isAccountFilled.value, "text", isTextFilled.value, "tit", isTitleFilled.value, "day", isDateFilled.value, "time", isTimeFilled.value);
      //"ch", isChannelFilled.value, " ac", isAccountFilled.value, "tit", isTextFilled.value, "day", isDateFilled.value, "time", isTimeFilled.value
      const fieldName = field.replace('pending_', '').replace('edit_', '');

      if (fieldName === 'channel') {
        // Ensure value is an array and trim each element if it's a string
        let newValues = Array.isArray(value) ? value.map(val => typeof val === 'string' ? val.trim() : val) : [value];

        // Filter out empty strings
        newValues = newValues.filter(val => typeof val === 'string' && val.trim() !== '');

        // Update pending_cast.value[channel] accordingly
        pending_cast.value[fieldName] = newValues.length > 0 ? Array.from(new Set(newValues)) : [];
      } else {
        // For other fields, simply update the value
        pending_cast.value[fieldName] = value;
      }

      // console.log('Updated pending_cast:', pending_cast.value);
    };


    function handleChannelName(payload) {
      handleInputChange("pending_channel", payload)
    };

    function handleUpdateCast(id, fid) {

      store.commit('showModalButtonLoading', true)
      const data = pending_cast.value;

      const startDate = data.startDate;
      const startTime = data.startTime;

    

      // Combine startDate and startTime into a single date string
      const startDateTimeString = `${startDate}T${startTime}`;
      // Create a Date object from the combined date string
      const startDateObj = new Date(startDateTimeString);

      const iowaTime = new Date(startDateObj.toLocaleString('en-US', { timeZone: 'America/Chicago' }));

      // Extract the day, month, year, and hour components from the Iowa time
      const day = iowaTime.getDate();
      const month = iowaTime.getMonth() + 1; // Month is zero-based, so we add 1
      const year = iowaTime.getFullYear().toString().slice(-2); // Get last two digits of the year
      const hour = iowaTime.getHours();
      const minute = iowaTime.getMinutes();

      // Format the components into a string in the "DD-MM-YY-HH" format
      const timezone = `${day}-${month}-${year}-${hour}-${minute}`;

      // Calculate the end date by adding 1 hour to the start date
      const endDateObj = new Date(startDateObj.getTime() + (1 * 60 * 60 * 1000));

      // Create Firestore Timestamp objects for start and end dates
      const startTimestamp =  Timestamp.fromDate(startDateObj);
       
      const endTimestamp =  Timestamp.fromDate(endDateObj);

      // console.log("timezone", timezone)

      const update_cast_submit = {
          title: data.title,
          text: data.text,
          image: data.image_url,
          channel: data.channel,
          start: startTimestamp,
          end: endTimestamp,
          timezone: timezone
      };

        store.dispatch('updateEvent', { id: id, fid: fid, update_cast_submit })
        .then(() => {

          store.dispatch('addEventData');

            var editPendingCastOffCanvas = document.getElementById('editPendingCastOffCanvas');

            if (editPendingCastOffCanvas) {
              var editPendingCastOffCanvas = bootstrap.Offcanvas.getInstance(editPendingCastOffCanvas)
              editPendingCastOffCanvas.hide();
            
            } 
            store.commit('showModalButtonLoading', false)
        }).catch(error => {
          // Handle errors, such as displaying an error message
          console.error('Error submitting form data:', error);
        });

    }

    function handleDeleteCast(id, fid) {
      store.commit('showModalButtonLoading', true)
      // console.log("delete cast id: ", id, fid)
      store.dispatch('deleteEvent', {id, fid})
      .then(() => {

          store.dispatch('addEventData');

            var editPendingCastOffCanvas = document.getElementById('editPendingCastOffCanvas');

            if (editPendingCastOffCanvas) {
              var editPendingCastOffCanvas = bootstrap.Offcanvas.getInstance(editPendingCastOffCanvas)
              editPendingCastOffCanvas.hide();
            
            } 
            store.commit('showModalButtonLoading', false)
        }).catch(error => {
          // Handle errors, such as displaying an error message
          console.error('Error submitting form data:', error);
        });

    }

    function showEditOrDeleteCastModal(payload) {

      let timezone = payload.start; // Ensure this is defined and valid
      let [date, time] = timezone.split(" ");

      const edit_channel = document.getElementById('edit_channel');
      if (edit_channel) {
        edit_channel.value = payload.channel[0];
      } 
        

      pending_cast.value = {
        id: payload.id,
        title: payload.title,
        text: payload.text,
        account: payload.accountId,
        image_url: payload.image,
        channel: payload.channel[0],
        startDate: date,
        startTime: time
      }
      // console.log("show modal with data:", pending_cast.value)

      var editPendingCastOffCanvas = document.getElementById('editPendingCastOffCanvas');

      if(editPendingCastOffCanvas) {
        editPendingCastOffCanvas.addEventListener('hidden.bs.offcanvas', function () {
              resetPendingCast()
          });
      }
      var editPendingCastOffCanvas = new bootstrap.Offcanvas(editPendingCastOffCanvas);
          editPendingCastOffCanvas.show();
    }

   
    function showScheduleCastModal(payload) {
      var newPendingCastOffCanvas = document.getElementById('newPendingCastOffCanvas');
      if (newPendingCastOffCanvas) {
        
          newPendingCastOffCanvas.addEventListener('hidden.bs.offcanvas', function () {
              resetPendingCast()
          });

          const currentTime = new Date(); 
          const selectedTime = new Date(payload); 

          const eventDate = new Date(selectedTime);
          const eventYear = eventDate.getFullYear();
          const eventMonth = String(eventDate.getMonth() + 1).padStart(2, '0');
          const eventDay = String(eventDate.getDate()).padStart(2, '0');
          const eventHour = String(eventDate.getHours()).padStart(2, '0');
          const eventMinutes = String(eventDate.getMinutes()).padStart(2, '0');

          const startDateInput = document.getElementById("startDate");
          const startTimeInput = document.getElementById("startTime");
        
          if (startTimeInput) {
            startTimeInput.value = `${eventHour}:${eventMinutes}`; 
          }

          if (startDateInput) {
            startDateInput.value = `${eventYear}-${eventMonth}-${eventDay}`; 
          }

          handleInputChange('startDate', startDateInput.value)
          handleInputChange('startTime', startTimeInput.value)

          var newPendingCastOffCanvas = new bootstrap.Offcanvas(newPendingCastOffCanvas);
          newPendingCastOffCanvas.show();
      } else {
          console.error("Offcanvas element not found.");
      }
    }
    

    function imageUpload() {
      if (imageUploadField.value) {
        imageUploadField.value.click(); // Trigger a click on the input field
      }
    }

    function removeImage() {
      imageUploadField.value = null
      imageURL.value = null
      imageData.value = null
      // castCost.value = castCost.value - 1;
    }

    function generateFilename(username, timestamp, originalFilename) {
      // Concatenate the username, timestamp, and original filename
      const data = `${username}-${timestamp}-${originalFilename}`;
      // Create a hash of the concatenated data
      const hash = createHash('sha256').update(data).digest('hex');
      // Return the hash as the filename
      return hash;
    }

    function previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        imageData.value = true
        const currentUser = auth.currentUser
        const storeRef = storageRef(storage, currentUser.uid + '/images/' + uuid.v1()); // Create a reference to the file under 'images' directory

        uploadBytes(storeRef, file).then((snapshot) => {
          getDownloadURL(storeRef).then((downloadURL) => {
            // Set the download URL to the ref
            imageData.value = null
            imageURL.value = downloadURL;
            // castCost.value = castCost.value + 1;

            handleInputChange("image_url", downloadURL)

            // console.log("image update value is", imageURL.value, "")
          }).catch((error) => {
            console.error('Error getting download URL:', error);
          });
        });
      } else {
        // console.log("no file")
      }
    }


    onMounted(() => { 



      const fontAwesomeInject = document.createElement("script");
      fontAwesomeInject.setAttribute(
        "src",
        "https://kit.fontawesome.com/5d9d5dc2a0.js"
      );
      fontAwesomeInject.setAttribute(
        "crossorigin",
        "anonymous"
      );
      document.head.appendChild(fontAwesomeInject);

      /// https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js

      const bootstrapPopperInject = document.createElement("script");
      bootstrapPopperInject.setAttribute(
        "src",
        "https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"
      );
      bootstrapPopperInject.setAttribute(
        "crossorigin",
        "anonymous"
      );
      document.head.appendChild(bootstrapPopperInject);

      var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
      })
    });

    // Call the action to listen for linked accounts changes
    store.dispatch('listenForLinkedAccountsChanges'); 

    // Get linkedAccounts from Vuex store
    const linkedAccounts = computed(() => store.getters.linkedAccounts);
    const newEventDateTime = computed(() => store.getters.newEventDateTime);
    const isEventModalVisible = computed(() => store.getters.isEventModalVisible);
    const modalButtonLoading = computed(() => store.getters.modalButtonLoading);

    const showModalButtonLoading = computed(() => store.state.showModalButtonLoading )

    // Computed properties to check form completion
    const isTitleFilled = computed(() => {
      const filled = pending_cast.value.title.trim() !== "";
      // console.log("Title Filled:", filled);
      return filled;
    });

    

    const isChannelFilled = computed(() => {
      const filled = pending_cast.value.channel.length > 0
      // console.log("Channel Filled:", filled);
      return filled;
    });
    const isAccountFilled = computed(() => {
      const filled = pending_cast.value.account.length > 0;
      // console.log("Account Filled:", filled);
      return filled;
    });
    const isTextFilled = computed(() => {
      const filled = pending_cast.value.text.trim() !== "";
      // console.log("Description Filled:", filled);
      return filled;
    });
    const isDateFilled = computed(() => {
      const filled = pending_cast.value.startDate.trim() !== "";
      // console.log("Date Filled:", filled);
      return filled;
    });
    const isTimeFilled = computed(() => {
      const filled = pending_cast.value.startTime.trim() !== "";
      // console.log("Time Filled:", filled);
      return filled;
    });

    const isFormFilled = computed(() => {
      // const filled = isTitleFilled.value && isChannelFilled.value && isAccountFilled.value && isDescriptionFilled.value;
      const filled = isTitleFilled.value && isTextFilled.value && isChannelFilled.value && isDateFilled.value && isTimeFilled.value && isAccountFilled.value
      // console.log("Form Filled:", filled);
      return filled;
    });
    const isImageLoaded = computed(() => {
      // If imageData is null, we assume image is not required
      if (imageData.value === null) {
        // console.log("Image Loaded: Image not required");
        return true; // Image not required, always consider as loaded
      }

      // If imageData is true, imageURL must be provided
      const loaded = imageData.value === true && imageURL.value !== null;
      // console.log("Image Loaded:", loaded);
      return loaded;
    });

    // Disable state for the button
    const isButtonDisabled = computed(() => {
      const disabled = !isFormFilled.value || !isImageLoaded.value;
      // console.log("Button Disabled:", disabled);
      return disabled;
    });


    // Watch for changes in linkedAccounts
    // watch(linkedAccounts, () => {
    //   console.log('Linked Accounts Updated:', linkedAccounts.value);
    // });

    function addEventDataToCalendar(eventData) {
      // console.log("added new events to calendar")
      calendarEvents.value = eventData; // Update the calendarEvents ref
    }

  function handleScheduleNewCast() {
    store.commit('showModalButtonLoading', true)
    const rawCast = pending_cast.value;  
    const accountFids = rawCast.account.map(acc => Number(acc.value));

    const startDate = rawCast.startDate;
    const startTime = rawCast.startTime;

    // Combine startDate and startTime into a single date string
    const startDateTimeString = `${startDate}T${startTime}`;
    // Create a Date object from the combined date string
    const startDateObj = new Date(startDateTimeString);

    const iowaTime = new Date(startDateObj.toLocaleString('en-US', { timeZone: 'America/Chicago' }));

    // Extract the day, month, year, and hour components from the Iowa time
    const day = iowaTime.getDate();
    const month = iowaTime.getMonth() + 1; // Month is zero-based, so we add 1
    const year = iowaTime.getFullYear().toString().slice(-2); // Get last two digits of the year
    const hour = iowaTime.getHours();
    const minute = iowaTime.getMinutes();

    // Format the components into a string in the "DD-MM-YY-HH" format
    const timezone = `${day}-${month}-${year}-${hour}-${minute}`;

    // Calculate the end date by adding 1 hour to the start date
    const endDateObj = new Date(startDateObj.getTime() + (1 * 60 * 60 * 1000));

    // Create Firestore Timestamp objects for start and end dates
    const startTimestamp =  Timestamp.fromDate(startDateObj);
    const endTimestamp =  Timestamp.fromDate(endDateObj);

    const pending_cast_submit = {
        title: rawCast.title,
        text: rawCast.text,
        account: accountFids,
        image: rawCast.image_url,
        channel: rawCast.channel,
        start: startTimestamp,
        end: endTimestamp,
        timezone: timezone
    };

    store.dispatch('storeEvent', pending_cast_submit)
    .then(() => {

      store.dispatch('addEventData');

      var offcanvasElement = document.getElementById('newPendingCastOffCanvas');

      if (offcanvasElement) {
        var newPendingCastOffCanvas = bootstrap.Offcanvas.getInstance(offcanvasElement)
        newPendingCastOffCanvas.hide();

      } 
      store.commit('showModalButtonLoading', false)
    }).catch(error => {
      // Handle errors, such as displaying an error message
      console.error('Error submitting form data:', error);
    });

    // console.log("cast to submit", pending_cast_submit);
  }

    

    return {
      calendarEvents,
      newEvent,
      linkedAccounts,
      newEventDateTime,
      isEventModalVisible,
      modalButtonLoading,
      imageUpload,
      imageUploadField,
      previewImage,
      imageURL,
      imageData,
      removeImage,
      isButtonDisabled,
      castCost,
      isDescriptionLong,
      handleInputChange,
      showScheduleCastModal,
      showEditOrDeleteCastModal,
      handleChannelName,
      pending_cast,
      handleScheduleNewCast,
      showModalButtonLoading,
      resetPendingCast,
      handleDeleteCast,
      handleUpdateCast
  
    };

   

  },
   

};
</script>

<style>

.cast_textarea {
  display: block;
  width: 100%;
  resize: both;
  line-height: 20px;
  font-family: inherit;
  font-size: inherit;
  padding: 1px 6px;
  min-width: 100%;
  min-height: 75px; 
  border-bottom: 1px solid #D3D3D3;
  caret-color: black;
}


.cast_textarea[contenteditable]:empty::before {
  content: "Start typing a new cast here...";
  color: gray;
}


/* Set the background color for the entire page */
:root {
  --bs-body-bg: rgba(245,244,250,255) !important;
}

.fc-card {
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(46, 38, 61, 0.12);
    background-color: #fff;
}
#imagePreview img{
  max-width: 100%;
}
.link-account {
  border: 2px dashed #ADD8E6;
  text-align: center;
}

.linked-account {
  text-align: center;
}

.sx-vue-calendar-wrapper {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.container-custom {
    display: flex;
    max-width: 1500px;
}
.content {
    flex: 1;
    /* background-color: #f1f1f1; */
    padding: 1em;
}
.flex-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.flex-item {
    /* background-color: #ccc; */
    flex: 1 1 calc(25% - 20px); /* Base width for four columns */
    margin-bottom: 10px;
}

.flex-item.wide-25 {
    flex: 1 1 calc(25% - 20px); /* Span 2 columns */
    max-width: calc(25% - 20px);
}

.flex-item.wide-33 {
    flex: 1 1 calc(33% - 20px); /* Span 2 columns */
    max-width: calc(33% - 20px);
}

.flex-item.wide-50 {
    flex: 1 1 calc(50% - 20px); /* Span 3 columns */
}

.flex-item.wide-75 {
    flex: 1 1 calc(75% - 20px); /* Span 3 columns */
}

.flex-item.wide-full {
    flex: 1 1 calc(100% - 20px); /* Span 4 columns */
}


@media (min-width: 1000px) {
    .nav {
        display: none !important;
    }
    .offcanvas-lg {
        display: block;
        width: 300px;
        max-width: 100%;
        background-color: #f4b41a;
        padding: 1em;
        padding-left: 0px;
    }
}


</style>
