import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { VueFire, VueFireAuth, getCurrentUser } from 'vuefire'
import { firebaseConfig } from './firebase/config'
import 'bootstrap/dist/css/bootstrap.min.css'
import VueApexCharts from "vue3-apexcharts";
import GoogleAnalytics from './GoogleAnalytics/google-analytics.js'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

// Add all icons to the library
library.add(fas, far, fab)

const firebaseApp = initializeApp(firebaseConfig);

const app = createApp(App)
  .use(VueFire, {
    firebaseApp,
    modules: [
      VueFireAuth(),
    ],
  }).component('font-awesome-icon', FontAwesomeIcon)
  .use(router).use(GoogleAnalytics).use(VueApexCharts).use(store).mount('#app')
