// src/plugins/google-analytics.js

export default {
  install(app, options) {
    // Load Google Analytics script
    let script = document.createElement('script')
    script.async = true
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-RWMEH4SV86'
    document.head.appendChild(script)

    // Initialize dataLayer and gtag function
    window.dataLayer = window.dataLayer || []
    function gtag() {
      dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', 'G-RWMEH4SV86')

    // Make gtag available globally
    window.gtag = gtag

    app.config.globalProperties.$sendAnalyticsEvent = (eventName, eventParams) => {
        gtag('event', eventName, eventParams)
      }
  }
}