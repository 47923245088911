<template>
  <div class="container">
    <div class="input-container">
      <input
        type="text"
        :id="inputId"
        class="form-control"
        placeholder="Channel"
        v-model="query"
        @focus="showPreselected"
        @input="fetchResults"
      />
    </div>
    <div class="results-container">
        <p class="mb-1 col-md-12 text-center"><small v-if="results.length < 1 && !loading">Start typing to search channels</small> <small v-if="loading"><i class="fas fa-spinner fa-spin"></i></small></p>
        <div
          class="result-item"
          v-for="result in results"
          :key="result.id"
          @click="selectResult(result.id)"
        >
          <img :src="result.image_url || defaultImageUrl" /> {{ result.name }} | <small><i class="fa-solid fa-user"></i> {{ formatFollowerCount(result.follower_count) }}</small>
        </div>
        
        <hr />
        <p class="mb-2 col-md-12"><small> <i class="fa-solid fa-bolt"></i> Suggested</small></p>
        <div
          class="result-item"
          v-for="item in preselectedItems"
          :key="item.name"
          @click="selectResult(item.id)"
        >
          <img :src="item.channel_image || defaultImageUrl" /> {{ item.name }}
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { auth, db } from '../firebase/config'
import { sendSignInLinkToEmail, signInWithEmailLink, signOut, onAuthStateChanged, getAuth, getAdditionalUserInfo } from 'firebase/auth'
import { collection, getDoc, doc, addDoc, deleteDoc,setDoc, getDocs, query, where, onSnapshot, Timestamp } from "firebase/firestore";

export default {
  props: {
    inputId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      query: '',
      results: [],
      preselectedItems: [
        { name: 'degen', channel_image: 'https://imagedelivery.net/BXluQx4ige9GuW0Ia56BHw/4728a50a-1669-4334-1f57-9473c04a2500/original', id: 'degen',  },
        { name: 'founders', channel_image: 'https://warpcast.com/~/channel-images/founders.png', id: 'founders' },
        { name: 'warpcast', channel_image: 'https://ipfs.decentralized-content.com/ipfs/bafkreifezhnp5wzgabkdbkb6d65oix4r5axibupv45r7ifxphl4d6qqnry', id: 'warpcast' },
        { name: 'farthercast', channel_image: 'https://imagedelivery.net/BXluQx4ige9GuW0Ia56BHw/ca74ec9d-9fb0-4811-26e4-10c86ef35a00/original', id: 'farthercast' },
        { name: 'memes', channel_image: 'https://i.seadn.io/gcs/files/1f4acfc1e6831eb38e9453ce34ac79f8.png?auto=format&dpr=1&w=512', id: 'memes' },
        { name: 'degen lotto', channel_image: 'https://i.imgur.com/oytyUss.gif', id: 'degen-lotto' }
      ],
      loading: false, // Add loading state
      showPreselectedItems: false,
      defaultImageUrl: 'https://i.imgur.com/m2gN7d9.jpg', 
      topChannels: []
    };
  },
  watch: {
    query(newQuery, oldQuery) {
      if (newQuery === '' && oldQuery !== '') {
        // Emit event only when the query becomes empty and was not empty before
        this.$emit('channelName', '');
      }
    }
  },
  created() {
    this.fetchTopChannels();
  },
  methods: {
    formatFollowerCount(count) {
      if (count < 1000) {
        return '<1k';
      } else {
        return (count / 1000).toFixed(1) + 'k';
      }
    },
    fetchResults() {
      if (this.query.trim().length > 1) {
        this.loading = true; // Set loading to true when making the request
        axios.get(`https://api.neynar.com/v2/farcaster/channel/search?q=${this.query}`, {
          headers: {
            accept: 'application/json',
            api_key: '6809F181-7E41-4F10-923F-723B5E70BF07'
          }
        })
        .then(response => {
          if (Array.isArray(response.data.channels)) {
            this.results = response.data.channels.slice(0, 20);
          } else {
            console.error('Error: Response data does not contain channels array');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        })
        .finally(() => {
          this.loading = false; // Set loading to false after the request is complete
        });
      } else {
        this.results = [];
      }
    },
    selectResult(value) {
      this.query = value; // Set the input field value to the clicked result
      this.$emit('channelName', value);
      this.results = []; // Close the results container
      this.showPreselectedItems = false; // Hide preselected items
    },
    showPreselected() {
      this.showPreselectedItems = true;
    },
    async fetchTopChannels() {
      try {
        
        const userId = auth.currentUser.uid;
        const docRef = doc(db, 'users', userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const favChannels = docSnap.data().fav_channels;
          if (favChannels) {
            const channelsArray = Object.keys(favChannels).map(key => ({
              name: key,
              id: key,
              ...favChannels[key]
            }));
            channelsArray.sort((a, b) => b.post_count - a.post_count);
            this.topChannels = channelsArray.slice(0, 6);

            // Fill up to 6 with preselectedItems if necessary
            const usedIds = new Set(this.topChannels.map(channel => channel.id));
            const additionalItems = this.preselectedItems.filter(item => !usedIds.has(item.id)).slice(0, 6 - this.topChannels.length);
            this.preselectedItems = [...this.topChannels, ...additionalItems];

          
          }
        } else {
          // console.log('No such document!');
        }
      } catch (error) {
        console.error('Error getting document:', error);
      }
    }
  }
};
</script>

<style>
.container {
  position: relative;
}

.input-container {
  position: relative;
}

.spinner {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  /* Adjust styles for the spinner container */
}

.results-container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  max-height: 350px;
  overflow-y: auto;
}

.result-item {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px 10px;
  font-size: .875rem;
  line-height: 1.25rem;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 5px;
}

.result-item img {
  max-width: 15px;
  max-height: 15px;
  border-radius: 10px;
}
.result-item small {
    color: grey;
}

hr {
  width: 100%;
  margin-top: 10px;
}
</style
