<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">
            <div v-if="isLoading" class="text-center my-5">
                <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <p class="mt-2">Loading account information...</p>
            </div>


        <div v-else  class="banner p-3 bg-light border rounded">
          <h4 class="mb-3">The 🎩 stays on!</h4>
          <p class="mb-3">
            Show your support and help farthercast succeed by automating a daily tip. 
          </p>
          <p class="mb-3"><small>You can set different amounts for each account or turn off tipping for specific accounts.</small></p>
          <div v-for="account in linkedAccounts" :key="account.fid" class="mb-3 p-2 border rounded">
            <template v-if="allowances[account.fid] > 0">
              <!-- Existing content for accounts with allowance -->
              <div class="d-flex justify-content-between align-items-center mb-2">
                <strong>{{ account.username }}</strong>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="'toggle-' + account.fid"
                    v-model="getAccountSetting(account.fid).isEnabled"
                  >
                  <label class="form-check-label" :for="'toggle-' + account.fid">
                    {{ getAccountSetting(account.fid).isEnabled ? 'On' : 'Off' }}
                  </label>
                </div>
              </div>
              <div class="input-group">
                <span class="input-group-text">🎩</span>
                <input
                  type="number"
                  class="form-control"
                  v-model="getAccountSetting(account.fid).amount"
                  :disabled="!getAccountSetting(account.fid).isEnabled"
                  placeholder="Enter Tip Amount"
                  step="5"
                  min="5"
                  :required="getAccountSetting(account.fid).isEnabled"
                >
              </div>
              <div v-if="invalidAmounts[account.fid]" class="text-danger mt-1">
                Must be > 0
              </div>
              <div v-if="allowanceMessages[account.fid]" class="text-danger mt-1">
                  {{ allowanceMessages[account.fid] }}
              </div>
            </template>
            <template v-else>
              <!-- Content for accounts without allowance -->
              <p class="text-center p-2">
              <span class="display-5 text-danger"><font-awesome-icon :icon="['fas', 'fa-person-drowning']" /></span><br/>
              It seems you don't have an allowance for account <strong>{{ account.username }}</strong></p>
            </template>
          </div>
          <div  v-if="hasAnyAllowance">
            <button
              class="btn btn-primary"
              @click="handleUpdateClick"
              :disabled="isSaving || !isFormValid"
            >
              {{ isSaving ? 'Saving...' : 'Update' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { app, firebaseApp, db, Timestamp, auth, rdb } from '../firebase/config';
import { ref as rdbref, push, set } from "firebase/database"; 
import { getFirestore, doc, getDoc, collection, getDocs } from 'firebase/firestore'
import axios from 'axios'

export default {
  setup() {
    const store = useStore()
    const accountSettings = ref({})
    const isSaving = ref(false)
    const isLoading = ref(true)

    const linkedAccounts = computed(() => store.state.linkedAccounts)

    const hasEnabledAccounts = computed(() => 
      Object.values(accountSettings.value).some(setting => setting.isEnabled)
    )
    const hasAnyAllowance = computed(() => {
      return Object.values(allowances.value).some(allowance => allowance > 0)
    })

    const invalidAmounts = computed(() => {
      const invalid = {};
      for (const [fid, setting] of Object.entries(accountSettings.value)) {
        if (setting.isEnabled && (parseFloat(setting.amount) <= 0 || isNaN(parseFloat(setting.amount)))) {
          invalid[fid] = true;
        }
      }
      return invalid;
    });

    const isFormValid = computed(() => {
      return Object.keys(invalidAmounts.value).length === 0;
    });

    function getAccountSetting(fid) {
      if (!accountSettings.value[fid]) {
        accountSettings.value[fid] = {
          isEnabled: false,
          amount: ''
        }
      }
      return accountSettings.value[fid]
    }

    async function fetchAccountSettings() {
      const currentUser = auth.currentUser
      if (!currentUser) {
        console.error('No user is currently signed in')
        return
      }

      const db = getFirestore(firebaseApp)
      const linkedAccountsRef = collection(db, 'daily_support', currentUser.uid, 'linkedSupportAccounts')

      try {
        const querySnapshot = await getDocs(linkedAccountsRef)
        querySnapshot.forEach((doc) => {
          const data = doc.data()
          accountSettings.value[doc.id] = {
            isEnabled: data.status === 'on',
            amount: data.amount
          }
        })
      } catch (error) {
        console.error('Error fetching account settings:', error)
      }
    }

    function initializeAccountSettings() {
      linkedAccounts.value.forEach(account => {
        getAccountSetting(account.fid)
      })
    }

    function delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }

    const allowanceMessages = ref({})
    const allowances = ref({})

    async function fetchAllAllowances() {
      for (const account of linkedAccounts.value) {
        const allowance = await fetchTipAllowance(account.fid)
        allowances.value[account.fid] = allowance
        // allowances.value[402978] = 0
        // console.log(allowances.value)
      }
    }

    async function fetchTipAllowance(fid) {
      try {
        const response = await axios.get(`https://api.degen.tips/airdrop2/allowances?fid=${fid}`)
        const allowances = response.data
        if (allowances && allowances.length > 0) {
          const fullAllowance = allowances[0].tip_allowance
          return fullAllowance - Math.floor((fullAllowance * 0.05))
        }
      } catch (error) {
        console.error('Error fetching tip allowance:', error)
      }
      return null
    }

    async function handleUpdateClick() {
      isSaving.value = true;
      const currentUser = auth.currentUser;
      if (!currentUser || !isFormValid.value) {
        isSaving.value = false;
        return;
      }

      const tasksRef = rdbref(rdb, 'tasks');
      allowanceMessages.value = {}

      for (const account of linkedAccounts.value) {
        const setting = accountSettings.value[account.fid];
        let amount = parseFloat(setting.amount) || 5;

        if (setting.isEnabled && amount > 0) {
          const tipAllowance = await fetchTipAllowance(account.fid)
          
          if (tipAllowance !== null && amount > tipAllowance) {
            allowanceMessages.value[account.fid] = `Greater than Allowance, Using Allowance of ${tipAllowance} instead`
            amount = tipAllowance
          }

          try {
            const newTaskRef = push(tasksRef);
            await set(newTaskRef, {
              type: 'daily_support',
              fid: account.fid,
              status: 'on',
              amount: amount,
              uuid: currentUser.uid
            });
            console.log('Created task for account:', account.fid, 'Amount:', amount);
            
            await delay(100);
          } catch (error) {
            console.error('Error creating task for account:', account.fid, error);
          }
        } else {
          console.log('Skipped account:', account.fid, 'Status: Off or Amount: 0');
        }
      }

      console.log('All tasks processed');
      isSaving.value = false;
    }

    watch(linkedAccounts, () => {
      initializeAccountSettings()
    }, { immediate: true, deep: true })

    async function initializeData() {
      isLoading.value = true
      try {
        await initializeAccountSettings()
        await fetchAccountSettings()
        await fetchAllAllowances()
      } catch (error) {
        console.error('Error initializing data:', error)
      } finally {
        isLoading.value = false
      }
    }

    onMounted(async () => {
      initializeData()
    })

    return {
      linkedAccounts,
      accountSettings,
      hasEnabledAccounts,
      getAccountSetting,
      handleUpdateClick,
      isSaving,
      invalidAmounts,
      isFormValid,
      allowanceMessages,
      allowances,
      hasAnyAllowance,
      isLoading
    }
  }
}
</script>

<style scoped>
.banner {
  width: 100%;
  margin-top: 20px;
}
</style>