<template>
<div class="content">
  <div class="flex-container">
      <div class="flex-item navbar-brand">
        <!-- <i class="fa-solid fa-bars ms-3 menu-button" ></i> -->
        <font-awesome-icon class="ms-3" :icon="['fas', 'fa-bars']" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebar" aria-controls="offcanvasSidebar"/>
        <span class="ms-2">/farthercast </span>
      </div>
      <div class="flex-item header-right">
        <!-- <ul class="pagination"> -->
          <!-- <li class="page-item disabled"><span class="page-link"><span class="spinner-border spinner-border-sm" role="status" v-if="!userCredits"></span> <span v-if="userCredits">{{ formatCredits(userCredits) }}</span> CC</span></li> -->
          <!-- <li class="page-item disabled"><span class="page-link" data-bs-toggle="tooltip" data-bs-placement="top" title="Available in v2">buy</span></li>
          <li class="page-item disabled"><span class="page-link">redeem</span></li>
          <li class="ps-4"><a class="btn btn-info" href="https://warpcast.com/~/channel/farthercast" target="_blank">support</a></li> -->
          <!-- <li class="ps-4"><a class="btn btn-danger" @click="handleClick">logout</a></li> -->
        <!-- </ul> -->
      </div>
  </div>
</div>
<!-- 
  <nav class="flex-container">
    <div class="flex-item fc-card wide-75">
      <a class="navbar-brand mr-4"><i class="fa-solid fa-bars" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebar" aria-controls="offcanvasSidebar"></i>/farthercast </a>

      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item mr-3">
          <a class="nav-link disabled beta" href="#" tabindex="-1" aria-disabled="true">BETA</a>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">referral</a>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">api</a>
        </li>
      </ul>
    </div>
      <div class=" pt-2">
        <ul class="pagination">
          <li class="page-item disabled"><span class="page-link"> <span class="spinner-border spinner-border-sm" role="status" v-if="!userCredits"></span> <span v-if="userCredits">{{ formatCredits(userCredits) }}</span> CC</span></li>
          <li class="page-item disabled"><span class="page-link" data-bs-toggle="tooltip" data-bs-placement="top" title="Available in v2">buy</span></li>
          <li class="page-item disabled"><span class="page-link">redeem</span></li>
          <li class="ps-4"><a class="btn btn-info" href="https://warpcast.com/~/channel/farthercast" target="_blank">support</a></li>
          <li class="ps-4"><a class="btn btn-danger" @click="handleClick">logout</a></li>
        </ul>
      </div>
   
  </nav> -->
</template>


<script>
import { ref, onMounted, onUnmounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  setup() {

    const error = ref(null)
    const store = useStore()
    const router = useRouter()

    const handleClick = async () => {
      try {
        await store.dispatch('logout')
        // console.log("logout")
        router.push({ name: 'Login' })
      } catch (err) {
        // console.log(err)
        error.value = err.message
      }
    }

    const userCredits = computed(() => {
      if (store.state.userCredits !== null) {
        return store.state.userCredits.toLocaleString(); // Format credits with commas
      }
      return null;
    });

    const formatCredits = (credits) => {
      if (credits !== null) {
        return credits.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Custom formatting function
      }
      return null;
    };

    


    return { handleClick, userCredits, formatCredits }
  }
}

</script>



<style scoped>

.profile {
  width: 200px;
  font-size: 20px;
  text-align: center;
  position: fixed;
  top: -5px;
  right: 15px;

}
.navbar-brand {
    font-family: "Lusitana", serif;
    font-weight: 800;
    font-size: 30px;
    color: #410780;
    max-width: 250px;
}

.menu-button {
    color: #000;
    border: none;
    width: 50px;
    display: inline-block !important;
}

@media (min-width: 1000px) {
    .menu-button {
        display: none;
    }
    .header-right {
      max-width: 475px;
      min-width: 475px;
    }
}
</style>
