<template>
  <div id="app">
    <nav class="fixed-top">
      <ul>
        <li v-for="(section, index) in sections" :key="index">
          <a
            :href="`#${section.id}`"
            :class="{ active: activeSection === section.id }"
            @click.prevent="scrollToSection(section.id)"
          >
            {{ section.name }}
          </a>
        </li>
      </ul>
    </nav>

    <section id="home" class="fullpage">
      <div class="login-form">
        <div class="center">
          <img
            src="/logo-white.png"
            alt="Farthercast: Schedule Casts Logo"
            style="width: 100px"
          />
          <h1>farthercast</h1>
          <h3>
            Multi-Account: <br />
            Scheduler, Analytics, Campaigns and Rewards for Farcaster
          </h3>
          <div class="p-3" v-if="!showLoader">
            <button
              ref="neynarSignin"
              class="neynar_signin"
              data-client_id="589c91c0-3ff2-4b3a-bad8-3e3c9e0de579"
              data-success-callback="onSignInSuccess"
              data-text="Sign in with Farcaster"
            ></button>
          </div>
          <div class="p-3" v-if="loginStepsStatus">
            <p>{{ loginStepsStatus }}</p>
          </div>
          <div class="p-3" v-if="showLoader">
            <div class="spinner-border text-light" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div class="p-3">
            <p class="pt-5">
              Made with ❤️ by
              <a href="https://warpcast.com/web3withmark" target="_blank"
                >@web3withmark</a
              >
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="rewards" class="pt-5">
      <div class="container-fluid">
        <div class="row pt-4 mb-5">
          <div class="col-12">
            <h2>Current Top 10 Rewards</h2>
          </div>
        </div>

        <div class="grid-wrapper mb-5">
          <div class="grid-container">
            <div class="grid-item">
              <div class="d-flex align-items-center justify-content-between">
                <h4 class="mb-0">Pool</h4>
                <i class="fa-solid fa-piggy-bank icon"></i>
              </div>
              <div class="mt-3">
                <p class="display-3 mb-0">{{ currentPool }}</p>
              </div>
            </div>

            <div class="grid-item">
              <div class="d-flex align-items-center justify-content-between">
                <h4 class="mb-0">Burn</h4>
                <i class="fa-solid fa-fire icon"></i>
              </div>
              <div class="mt-3">
                <p class="display-3 mb-0">{{ toBurn }}</p>
              </div>
            </div>


            <div class="grid-item">
              <div class="d-flex align-items-center justify-content-between">
                <h4 class="mb-0">Lock</h4>
                <i class="fa-solid fa-lock icon"></i>
              </div>
              <div class="mt-3">
                <p class="display-3 mb-0">{{ toLock }}</p>
              </div>
            </div>
          </div>
        </div>
        
        <div class="container">
          <div class="row">
            <table class="table white-table p-5 text-center">
              <thead>
                <tr>
                  <th scope="col">Rank</th>
                  <th scope="col">Username</th>
                  <th scope="col">Display Name</th>
                  <th scope="col">Points</th>
                  <th scope="col">Possible Reward</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(user, index) in leaderboard" :key="user.username">
                  <th scope="row">{{ index + 1 }}</th>
                  <td><a :href="'https://warpcast.com/' + user.username" target="_blank">{{ user.username }}</a></td>
                  <td>{{ user.display_name }}</td>
                  <td>{{ user.points }}</td>
                  <td>{{ user.possible_reward }} $DEGEN</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>  
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { useStore } from "vuex";
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';

export default {
  setup() {
    const activeSection = ref("home");
    const leaderboard = ref([]);
    const timeUntilMidnight = ref('');
    const currentPool = ref(0);
    const toBurn = ref(0);
    const toLock = ref(0);
    let unsubscribe;
    const showLoader = ref(false);
    const error = ref(null);
    const store = useStore()
    const referralCode = ref(null);

    const sections = [
      { id: "home", name: "Home" },
      { id: "rewards", name: "Rewards" }
    ];

    const fetchLeaderboard = () => {
      const db = getFirestore();
      const leaderboardRef = doc(db, 'leaderboard', 'season_two');

      unsubscribe = onSnapshot(leaderboardRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          currentPool.value = data.currentPool;
          toBurn.value = data.toBurn;
          toLock.value = data.toLock;
          
          // Sort the leaderboard by points in descending order
          const sortedLeaderboard = data.leaderboard.sort((a, b) => b.points - a.points);
          
          // Take only the top 15 users
          leaderboard.value = sortedLeaderboard.slice(0, 10);
        }
      });
    };


    const loginStepsStatus = computed(() => store.getters.loginStepStatus);

    const scrollToSection = (id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };

    const updateActiveSection = () => {
      const scrollPosition = window.scrollY;
      for (const section of sections) {
        const element = document.getElementById(section.id);
        if (element) {
          const { offsetTop, offsetHeight } = element;
          if (scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight) {
            activeSection.value = section.id;
            break;
          }
        }
      }
    };

    onMounted(() => {

    const urlParams = new URLSearchParams(window.location.search);
    referralCode.value = urlParams.get('r');

    if (referralCode.value) {
        console.log('Referral code:', referralCode.value);
        // You can store this in Vuex or use it as needed
      }
      

      const fontAwesomeInject = document.createElement("script");
      fontAwesomeInject.setAttribute(
        "src",
        "https://kit.fontawesome.com/5d9d5dc2a0.js"
      );
      fontAwesomeInject.setAttribute(
        "crossorigin",
        "anonymous"
      );
      document.head.appendChild(fontAwesomeInject);

      // Create script element
      const script = document.createElement('script');
      script.src = 'https://neynarxyz.github.io/siwn/raw/1.2.0/index.js';
      document.body.appendChild(script);

      
      

      window.onSignInSuccess = async function(data) {
          showLoader.value = true;
          store.commit("updateLoginStepStatus", "Checking or creating account")
          // console.log("neynar data:", data)

          const loginData = {
            ...data,
            referralCode: referralCode.value
          };
          store.dispatch('handleLogin', loginData)
      };




      window.addEventListener("scroll", updateActiveSection);
      updateActiveSection();
      fetchLeaderboard()
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", updateActiveSection);
      if (unsubscribe) {
        unsubscribe();
      }
    });

    return {
      activeSection,
      sections,
      scrollToSection,
      showLoader,
      loginStepsStatus,
      leaderboard,
      currentPool,
      toBurn,
      toLock,
    };
  },
};
</script>

<style scoped>

#app {
  padding-top: 60px; /* Adjust based on your navbar height */
  background: radial-gradient(circle, #7c0dcd, #410780);
  color: #fff;
}

.white-table.table>:not(caption)>*>* {
    color: white !important;
    border-color: black !important;
    
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid black;
    padding: 10px;
}

.white-table.table a {
    color: white !important;
}

.white-table.table {
    --bs-table-color: white !important;
    --bs-table-bg: transparent !important;
    --bs-table-border-color: white !important;
}

.white-table.table tbody + tbody {
    border-top-color: white !important;
}

nav {
  background-color: #333;
  padding: 10px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

nav li {
  margin: 0 15px;
}

nav a {
  color: white;
  text-decoration: none;
}

nav a.active {
  font-weight: bold;
  text-decoration: underline;
}

.fullpage {
  height: 100vh;
}


/* Your existing styles */
html {
  scroll-behavior: smooth;
}

body {
  overflow-y: auto;
  overflow-x: hidden;
}

h1 {
  font-size: 5em;
  margin: 0;
  text-align: center;
  padding: 0 1rem;
}

h2 {
  font-size: 2em;
  margin: 0;
  text-align: center;
  padding: 0 1rem;
}

p {
  font-size: 1em;
}

.fullpage a,
.fullpage span {
  text-decoration: none;
  font-weight: 600;
  background: rgba(255, 255, 255, 0.2);
  padding: 5px 10px;
  color: #fff;
  margin-left: 5px;
}

a {
  color: #fff;
}

/*  HOME SECTION */
#home .center {
  position: relative;
  top: 50%;
  justify-content: center;
  align-items: center;
  height: 500px;
  transform: translateY(-50%);
}

#home .login-form {
  text-align: center;
  width: 95%;
  margin: 0 auto;
  height: 100vh;
}

#home h1 {
  font-weight: 900;
  padding-bottom: 50px;
  font-family: "Lusitana", sans-serif;
}

#home p,
#home h1,
#home h2,
#home h3 {
  color: #fff;
}

.btn {
  max-width: 300px;
  padding: 1.5rem;
  font-size: 1.6rem;
  background-color: #410780;
  color: #fff;
}

.btn:hover,
.btn:focus,
.btn:active {
  background-color: #000 !important;
  border: 1px solid #000;
  color: #fff;
}

.grid-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-x: auto; /* Allow horizontal scrolling if needed */
}

.grid-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 10px; /* Add some padding for better mobile appearance */
  min-width: min-content; /* Ensure container is at least as wide as its content */
}

.grid-item {
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid black;
  border-radius: 10px;
  padding: 15px;
  flex: 1 0 auto; /* Allow items to grow but not shrink */
  min-width: 150px; /* Minimum width for each item */
  max-width: 300px; /* Maximum width for each item */
}

/* Adjust font sizes for smaller screens */
@media (max-width: 768px) {
  .grid-item h4 {
    font-size: 1rem;
  }
  .grid-item .display-3 {
    font-size: 2rem;
  }
}

/* Further adjustments for very small screens */
@media (max-width: 480px) {
  .grid-container {
    gap: 10px;
  }
  .grid-item {
    min-width: 120px;
    padding: 10px;
  }
}


@media screen and (max-width: 1200px) {
  h1 {
    font-size: 2.5em;
  }
}
</style>
